import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import toast from 'react-hot-toast';
import IdentityValidationStatus from '@/components/organisms/IdentityValidationStatus';
import IFrameOcr from '@/components/organisms/IFrameOcr';
import TransferApiTemplate from '@/components/templates/TransferApiTemplate';
import IdwallSdk from '@/components/organisms/IdwallSdk';
import MainTemplate from '@/components/templates/MainTemplate';
import useUserStatus from '@/hooks/useUserStatus';
import { useUserData } from '@/context/userContext';
import { getPromocode } from '@/services/api';
import { registrationStatus } from '@/helpers/constants';

import CafValidation from '@/components/organisms/CafValidation';
import useUpdateUser from '@/hooks/useUpdateUser';
import RegisterValidationErrorMessage from '@/components/molecules/RegisterValidationErrorMessage';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { extractErrorSlug } from '@/helpers/functions';
import PromocodePointsModalContent from '@/components/v2/Molecules/PromocodePointsModalContent';
import PromocodeBonusModalContent from '@/components/v2/Molecules/PromocodeBonusModalContent';
import BalanceWithCurrencySwitch from '@/components/molecules/BalanceWithCurrencySwitch';

const STATUS_PENDING = 2;
const STATUS_REJECTED = 3;
const STATUS_PROCESSING = 4;
const STATUS_COMPLETED = 5;
const STATUS_BLOCKED = 6;
const SIX_SECONDS = 6000;
const INTERVAL_SECONDS = 15000;
const TIMEOUT_SECONDS = 6000;

function ValidateIdentity() {
  const [points, setPoints] = useState(0);
  const [titleBonuspromocode, setTitleBonuspromocode] = useState('');
  const [showCompleteModal, setShowCompleteModal] = useState(true);
  const [hasToShowIframe, setHasToShowIframe] = useState(false);
  const { userData } = useUserData();
  const { pathname } = useLocation();
  const { getUserStatusAndRedirect } = useUserStatus();
  const [documentRejected, setDocumentRejected] = useState(false);
  const { updateUserData } = useUpdateUser();
  const { isMobileLayout } = useDeviceWidth();

  const alreadyInValidation = userData?.status >= STATUS_PROCESSING;
  const [error, setError] = useState('');
  const [documentSent, setDocumentSent] = useState(alreadyInValidation);

  // Referencias para los temporizadores
  const intervalIdRef = useRef(null);
  const timeoutIdRef = useRef(null);
  const isExternalFlow = pathname.includes('/external');
  const PageTemplate = isExternalFlow ? TransferApiTemplate : MainTemplate;

  const clearTimers = () => {
    clearTimeout(timeoutIdRef.current);
    clearInterval(intervalIdRef.current);
  };

  const getUserPromocode = async () => {
    try {
      const req = await getPromocode();
      if (req.data.points) {
        setPoints(req.data.points);
      } else {
        setTitleBonuspromocode(req.data.bonus.title);
      }
    } catch (err) {
      extractErrorSlug(err?.response);
    }
  };

  const restart = (skipValidationInstructions) => {
    const checker = () => {
      if (intervalIdRef.current) return;
      clearTimers();
      intervalIdRef.current = setInterval(() => {
        updateUserData();
      }, 15000);
    };

    timeoutIdRef.current = setTimeout(() => {
      checker();
    }, SIX_SECONDS);

    setHasToShowIframe(skipValidationInstructions);
    setDocumentSent(false);
    setError(false);
    return i18n.t('validateIdentity.resetSuccess');
  };

  const handleUrlStatus = () => {
    let newUrl = pathname;

    switch (userData?.status) {
      case STATUS_PENDING:
        newUrl += '?status=pending';
        break;
      case STATUS_REJECTED:
        newUrl += '?status=rejected';
        break;
      case STATUS_PROCESSING:
        newUrl += '?status=processing';
        break;
      default:
        break;
    }

    window.history.pushState(null, '', newUrl);
  };

  useEffect(() => {
    getUserStatusAndRedirect(userData?.status);
  }, [getUserStatusAndRedirect, userData]);

  useEffect(() => {
    getUserPromocode();
    if (userData.status <= STATUS_PROCESSING && !timeoutIdRef.current) {
      timeoutIdRef.current = setTimeout(() => {
        intervalIdRef.current = setInterval(() => {
          updateUserData();
        }, INTERVAL_SECONDS);
      }, TIMEOUT_SECONDS);
    }

    if (userData?.status >= registrationStatus.validateId) {
      setShowCompleteModal(false);
    }

    return () => {
      clearTimers();
    };
  }, []);

  useEffect(() => {
    const isRejected =
      userData.status === STATUS_BLOCKED || userData.status === STATUS_REJECTED;
    handleUrlStatus();
    if (userData?.status >= registrationStatus.waitingIdValidation) {
      setDocumentSent(true);
    } else if (userData?.status === registrationStatus.validateRegistration) {
      setDocumentSent(false);
    }

    if (showCompleteModal && userData?.status === STATUS_COMPLETED) {
      toast.success(i18n.t('registration.success'));
    }

    if (isRejected) {
      setError(true);
    }

    if (userData.status === STATUS_BLOCKED && timeoutIdRef.current) {
      clearTimers();
    }
  }, [userData, showCompleteModal]);

  return (
    <>
      <PageTemplate
        containerProps={{ gradient: true, background: 'black' }}
        headerProps={{ mobileFullVersion: true, background: 'black' }}
        wrapperProps={{ background: 'black' }}
        hideHeader={isExternalFlow}
        hideNavMenu={isExternalFlow}
      >
        {isMobileLayout && !hasToShowIframe && (
          <div className="w-[90%]">
            <BalanceWithCurrencySwitch />
          </div>
        )}

        {error || userData?.status === STATUS_BLOCKED ? (
          <div className="flex justify-center items-center h-4/5 gap-10 w-full">
            <RegisterValidationErrorMessage
              restartValidation={isMobileLayout ? () => restart(true) : restart}
            />
          </div>
        ) : !hasToShowIframe && !alreadyInValidation && !documentSent ? (
          <CafValidation renderIframe={() => setHasToShowIframe(true)} />
        ) : documentSent && !documentRejected ? (
          <IdentityValidationStatus
            isExternalFlow={isExternalFlow}
            setDocumentRejected={setDocumentRejected}
            alreadyInValidation={alreadyInValidation}
            error={error}
          />
        ) : (
          <div className="flex items-center justify-center h-full w-full">
            {userData?.kyc_provider === 'idwall' ? (
              <IdwallSdk
                setErrorMessage={setError}
                onSuccess={() => setDocumentSent(true)}
              />
            ) : (
              <IFrameOcr
                ocrProviderSlug={userData?.kyc_provider}
                setError={setError}
              />
            )}
          </div>
        )}
      </PageTemplate>
      {showCompleteModal && Boolean(points) && (
        <PromocodePointsModalContent
          handleCloseModal={() => setShowCompleteModal(false)}
          points={points}
        />
      )}
      {showCompleteModal && !points && titleBonuspromocode !== '' && (
        <PromocodeBonusModalContent
          handleCloseModal={() => setShowCompleteModal(false)}
          nameBonus={titleBonuspromocode}
        />
      )}
    </>
  );
}

export default ValidateIdentity;
