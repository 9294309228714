/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

import { ContentWrapper } from './styles';

function TermsAndConditionsBonus({ terms }) {
  return (
    <section className="p-0 flex flex-col items-center w-full h-fit">
      <ContentWrapper>
        <div className="flex flex-col items-center w-full max-w-[80%] h-fit">
          {terms && (
            <div
              className="bonus-terms h-[70vh]"
              dangerouslySetInnerHTML={{ __html: terms }}
            />
          )}
        </div>
      </ContentWrapper>
    </section>
  );
}

TermsAndConditionsBonus.propTypes = {
  terms: PropTypes.shape({}),
}.isRequired;

export default TermsAndConditionsBonus;
