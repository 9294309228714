import styled from 'styled-components';

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.info.light};
  font-size: ${({ theme }) => theme.font.size.small};
  font-weight: 400;
  text-align: center;
  width: 300px;
`;

export const ErrorText = styled(Text)`
  color: ${({ theme }) => theme.colors.danger};
  font-size: ${({ theme }) => theme.font.size.xSmall};
`;

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 90%;
  padding: 0 1rem;
  justify-content: space-evenly;
  position: relative;
  width: 100%;

  svg {
    margin: 2rem 0;
  }
`;

export const HideInDesktop = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;

  > * {
    &:first-child {
      margin-bottom: 10px;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const HideInMobile = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: block;
  }
`;

export const DeskWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    background: ${({ background }) =>
      background || 'linear-gradient(90deg, #2B2D2E 0%, #242525 100%)'};
    height: 100%;
    justify-content: space-between;
    min-height: 550px;
  }
`;
