import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import debounce from 'lodash.debounce';

import Tooltip from '@/components/atoms/Tooltip';
import CircularLoader from '@/components/atoms/CircularLoader';
import { useWalletData } from '@/context/walletContext';
import { getCurrencySymbol } from '@/helpers/stringFormat';
import i18n from '@/translate/i18n';

import { InputWrapper, Label, NumericInput, TooltipContent } from './styles';
import TwTitle from '../../TwTitle';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import useTour from '@/hooks/useTour';

const MAX_INPUT_LENGTH = 6;
const ONLY_NUMBERS_REGEX = /[^0-9]/g;
const INPUT_NUMBER_LOCALE = 'pt-BR';
const ICON_SIZE = 15;
const LOADING_ICON_SIZE = 20;
const TIME_DEBOUNCE = 5000;

function InputTransactionValue({
  content,
  currency,
  disabled,
  insufficientFund,
  isError,
  loading = false,
  value,
  valueInvalid,
}) {
  const { pathname } = useLocation();
  const { stateTour, nextStepTour } = useTour();
  const numericInputRef = useRef(null);

  const { run } = stateTour;

  const {
    walletData: {
      selectedWallet,
      transactionData,
      selectedBonus,
      transactionLimits,
    },
    setWalletData,
  } = useWalletData();

  const { amount } = transactionData;

  const { min_amount_per_transaction } = transactionLimits;

  const isFreeBonus =
    !selectedBonus?.min_amount_transaction && selectedBonus?.type === 'fixed';

  const handleChange = ({ target }) => {
    setWalletData((state) => ({
      ...state,
      transactionData: {
        ...state.transactionData,
        amount: Number(target.value.replace(ONLY_NUMBERS_REGEX, '')),
      },
    }));
  };

  const transferType = pathname?.replaceAll('/', '');
  const elemIdBase = `transaction-input-${transferType}`;
  const { isMobileLayout } = useDeviceWidth();

  const inputId =
    disabled && !isError
      ? `${elemIdBase}-details`
      : isError
      ? `${elemIdBase}-error`
      : elemIdBase;

  useEffect(() => {
    if (!run || numericInputRef.current !== document.activeElement) return null;
    const debouncedNextStepTour = debounce(() => {
      nextStepTour();
      numericInputRef.current.blur();
    }, TIME_DEBOUNCE);

    if (transactionData && transactionData.amount !== 0) {
      debouncedNextStepTour();
    }
    return () => {
      debouncedNextStepTour.cancel();
    };
  }, [transactionData]);

  return (
    <InputWrapper>
      <TwTitle
        size={isMobileLayout ? 'm_md' : 'd_md'}
        font="arboriaBold"
        classList="text-center"
      >
        {amount >= min_amount_per_transaction || isFreeBonus
          ? content
          : i18n.t('deposit.enterValue')}
      </TwTitle>

      <Label
        htmlFor={inputId}
        insufficientFund={insufficientFund}
        isError={isError}
        locked={disabled}
      >
        <Tooltip
          content={i18n.t('transfer.conversionDisclaimer')}
          disabled={!currency || currency === 'BRL'}
        >
          <TooltipContent className={`${valueInvalid && '!text-red-600'}`}>
            {loading ? (
              <CircularLoader size={LOADING_ICON_SIZE} />
            ) : (
              <>
                {getCurrencySymbol('pt-BR', currency || selectedWallet) || 'R$'}
                <NumericInput
                  length={
                    value?.toString()?.length || amount?.toString()?.length || 0
                  }
                  id={inputId}
                  type="text"
                  name={inputId}
                  value={
                    value?.toLocaleString(INPUT_NUMBER_LOCALE) ||
                    amount?.toLocaleString(INPUT_NUMBER_LOCALE) ||
                    0
                  }
                  placeholder="0"
                  maxLength={MAX_INPUT_LENGTH}
                  onChange={handleChange}
                  insufficientFund={insufficientFund}
                  isError={isError}
                  disabled={disabled}
                  autoFocus
                  className={`${valueInvalid && '!text-red-600'}`}
                  inputMode="numeric"
                  ref={numericInputRef}
                />
                {currency && currency !== 'BRL' && (
                  <AiOutlineInfoCircle size={ICON_SIZE} />
                )}
              </>
            )}
          </TooltipContent>
        </Tooltip>
      </Label>
    </InputWrapper>
  );
}

InputTransactionValue.propTypes = {
  content: PropTypes.string,
  currency: PropTypes.string,
  disabled: PropTypes.bool,
  insufficientFund: PropTypes.bool,
  isError: PropTypes.bool,
  value: PropTypes.number,
  valueInvalid: PropTypes.bool,
}.isRequired;

export default InputTransactionValue;
