import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';

import SectionDivider from '@/components/atoms/SectionDivider/styles';
import TransactionDetailsSummary from '@/components/molecules/TransactionDetailsSummary';
import TransferDetailsMerchantSummary from '@/components/molecules/TransferDetailsMerchantSummary';
import { useUserData } from '@/context/userContext';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import useUserPoints from '@/hooks/useUserPoints';
import { LinkTextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { formatCurrency, formatShortDate } from '@/helpers/stringFormat';
import { twTitleColorByStatus } from '@/helpers/constants';
import {
  ExternalButton,
  InternalButton,
} from '@/components/atoms/Buttons/Button/styles';
import TwTitle from '@/components/atoms/TwTitle';
import { getMerchant } from '@/services/api';

import {
  AmountResume,
  ContentWrapper,
  DetailsHeader,
  FlexWrapper,
  Section,
} from './styles';

function TransferDetails({ data }) {
  const location = useLocation();
  const { isMobileLayout } = useDeviceWidth();
  const { getPoints } = useUserPoints();
  const { userData } = useUserData();
  const [merchant, setMerchant] = useState(null);

  const merchantName = data?.details?.merchant_slug?.replaceAll('-', ' ');
  const totalAmount = data?.customer_bonus
    ? Number(data?.customer_bonus) + Number(data?.customer_amount)
    : data?.customer_amount;

  const merchantID = data?.details?.merchant_id;
  const merchantUsername =
    merchant && !merchant?.new_integration
      ? data?.details?.merchant_username
      : null;
  const detailsData = {
    id_transaction: data?.id,
    merchant: data?.details?.merchant_name ?? data?.details?.merchant_slug,
    id_transaction_merchant: data?.details?.merchant_txid,
    date: data?.created_at ? formatShortDate(data.created_at) : '',
    amount: formatCurrency(
      data?.customer_amount ?? 0,
      data?.customer_currency_iso,
    ),
    bonus: data?.customer_bonus
      ? formatCurrency(data?.customer_bonus ?? 0, data?.customer_currency_iso)
      : null,

    [`youTransferred.${data?.status}`]: formatCurrency(
      totalAmount ?? 0,
      data?.customer_currency_iso,
    ),
    ...(!userData?.points_blocked ? { points: data?.points?.points } : {}),
  };

  const isCompleted = data?.status === 'completed';
  const shouldRenderExternalButton = isCompleted && data?.details?.merchant_url;

  const getMerchantInfo = async () => {
    try {
      const { data: dataResponse } = await getMerchant(merchantID);
      setMerchant(dataResponse);
    } catch (error) {
      toast.error(i18n.t('error.historyTransaction.errorGetMerchant'));
    }
  };

  useEffect(() => {
    if (location?.state?.from === 'transfer-in-page') {
      getPoints();
    }
  }, []);

  useEffect(() => {
    getMerchantInfo();
  }, []);

  return (
    <ContentWrapper>
      <Section>
        {isMobileLayout && (
          <TwTitle
            id="transfer-details-title"
            size={isMobileLayout ? 'm_md' : 'd_lg'}
            font="arboriaBold"
            classList="text-center"
          >
            <Trans
              i18n={i18n}
              i18nKey={i18n.t(
                `transactionDetails.title.${data?.type}.${data?.status}`,
              )}
              components={[
                <span
                  className={twTitleColorByStatus[data?.status ?? 'default']}
                />,
              ]}
              tOptions={{
                merchant: merchantName ?? '',
              }}
            />
          </TwTitle>
        )}
        <TransactionDetailsSummary
          data={detailsData}
          bonus={data?.bonus}
          typeTransaction={data?.type}
          status={data?.status}
          merchantName={merchantName}
          hideTitle={isMobileLayout}
        />
        <FlexWrapper>
          {isMobileLayout && shouldRenderExternalButton ? (
            <ExternalButton
              href={data?.details?.merchant_url}
              target="_blank"
              id="transfer-details-mobile-external-button"
            >
              {i18n.t('transactionDetails.transferTo', {
                merchant:
                  data?.details?.merchant_name ?? data?.details?.merchant_slug,
              })}
            </ExternalButton>
          ) : (
            isMobileLayout && (
              <InternalButton to="/transfer" id="transfer-details-new-transfer">
                {i18n.t('transactionDetails.makeNewTransfer')}
              </InternalButton>
            )
          )}
          <LinkTextButton className="!text-lg" to="/transactions">
            {i18n.t('transactionDetails.goToHistory')}
          </LinkTextButton>
        </FlexWrapper>
      </Section>

      <SectionDivider height="90%" />
      <Section hideInMobile>
        <DetailsHeader>
          <TwTitle
            size={isMobileLayout ? 'm_lg' : 'd_lg'}
            font="arboriaBold"
            classList="text-center"
          >
            <Trans
              i18n={i18n}
              i18nKey={i18n.t(
                `transactionDetails.title.${data?.type}.${data?.status}`,
              )}
              components={[
                <span
                  className={`${
                    twTitleColorByStatus[data?.status ?? 'default']
                  }`}
                />,
              ]}
              tOptions={{
                merchant: merchantName ?? '',
              }}
            />
          </TwTitle>

          <TransferDetailsMerchantSummary
            username={merchantUsername}
            currencyIso={data?.details?.customer_currency_iso}
            merchant={{
              slug: data?.details?.merchant_slug,
              name: data?.details?.merchant_name,
            }}
          />
        </DetailsHeader>
        <AmountResume>
          <TwTitle
            size={isMobileLayout ? 'm_md' : 'd_md'}
            font="arboriaBold"
            classList="text-center"
          >
            <Trans
              i18n={i18n}
              i18nKey={`transactionDetails.amountResume.${data?.type}.${data?.status}`}
              components={[<span />]}
              tOptions={{
                merchant: merchantName ?? '',
              }}
            />
          </TwTitle>
          <strong id="transfer-details-amount">
            {formatCurrency(totalAmount, data?.customer_currency_iso)}
          </strong>
        </AmountResume>
        {shouldRenderExternalButton ? (
          <ExternalButton
            href={data?.details?.merchant_url}
            target="_blank"
            id="transfer-details-desktop-external-button"
          >
            {i18n.t('transactionDetails.transferTo', {
              merchant:
                data?.details?.merchant_name ?? data?.details?.merchant_slug,
            })}
          </ExternalButton>
        ) : (
          <InternalButton to="/transfer" id="transfer-details-new-transfer">
            {i18n.t('transactionDetails.makeNewTransfer')}
          </InternalButton>
        )}
      </Section>
    </ContentWrapper>
  );
}

TransferDetails.propTypes = {
  data: PropTypes.shape({
    customer_amount: PropTypes.number,
    customer_bonus: PropTypes.number,
    customer_currency_iso: PropTypes.number,
    details: PropTypes.shape({
      merchant_slug: PropTypes.string,
      merchant_username: PropTypes.string,
    }),
    id: PropTypes.number,
    status: PropTypes.string,
  }),
}.isRequired;

export default TransferDetails;
