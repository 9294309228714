import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LinkText = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.font.size.xSmall};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    font-size: ${({ theme }) => theme.font.size.small};
  }
`;
