import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import CheckRegistration from '@/components/molecules/CheckRegistration';
import TransferApiTemplate from '@/components/templates/TransferApiTemplate';
import MainTemplate from '@/components/templates/MainTemplate';
import useUserStatus from '@/hooks/useUserStatus';
import { getUserValidation } from '@/services/api';
import { registrationStatus } from '@/helpers/constants';
import { useUserData } from '@/context/userContext';

const TEN_SECONDS = 10000;
const VALIDATION_FIELDS = ['cpf'];

function ValidateRegister() {
  const { userData, setUserData } = useUserData();
  const { getUserStatusAndRedirect } = useUserStatus();
  const { pathname } = useLocation();

  const [userValidations, setUserValidations] = useState([]);
  const [isValidated, setIsValidate] = useState(false);
  const [error, setError] = useState(false);

  const isExternalFlow = pathname.includes('/external');
  const PageTemplate = isExternalFlow ? TransferApiTemplate : MainTemplate;

  const checkValidations = () => {
    const fieldsToCheck = userValidations.filter((item) =>
      VALIDATION_FIELDS.includes(item?.validation),
    );

    if (fieldsToCheck.every((item) => item?.status === 'validated')) {
      setIsValidate(true);
      setUserData((state) => ({
        ...state,
        status: registrationStatus.validateId,
      }));
    }

    if (fieldsToCheck.some((item) => item?.status === 'rejected')) {
      setError(true);
    }
  };

  useEffect(() => {
    getUserStatusAndRedirect(userData?.status);
  }, [getUserStatusAndRedirect, userData]);

  useEffect(() => {
    const updateStatus = async () => {
      try {
        const res = await getUserValidation(userData?.id);
        setUserValidations(res.data);
      } catch (err) {
        console.error(err?.response?.data?.message);
      }
    };

    let interval = null;

    if (!interval) {
      interval = setInterval(() => updateStatus(), TEN_SECONDS);
    }

    if (userData?.status === registrationStatus.validateId) {
      setError(true);
    } else if (userData?.status >= registrationStatus.waitingIdValidation) {
      setIsValidate(true);
      clearInterval(interval);
    }

    updateStatus();

    if (isValidated || error) {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isValidated, error]);

  useEffect(() => {
    if (userValidations?.length) {
      checkValidations();
    }
  }, [userValidations]);

  return (
    <PageTemplate
      headerProps={{ mobileFullVersion: true }}
      containerProps={{ gradient: false }}
    >
      <CheckRegistration
        validated={isValidated}
        error={error}
        isExternalFlow={isExternalFlow}
        name={userData?.name}
      />
    </PageTemplate>
  );
}

export default ValidateRegister;
