import React, { createPortal } from 'react-dom';

import { Trans } from 'react-i18next';
import TwTitle from '@/components/atoms/TwTitle';
import TwText from '@/components/v2/atoms/TwText';
import i18n from '@/translate/i18n';
import { ModalTutorialOnboarding } from '@/components/v2/Molecules/ModalTutorialOnboarding';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { TwModal } from '@/components/v2/atoms/TwModal';
import TwButton from '@/components/atoms/Buttons/TwButton';
import { ReactComponent as Hourglass } from '@/assets/timer.svg';

function Portal({
  isModalOpen,
  hasToShowTutorial,
  setIsModalOpen,
  setHasToShowTutorial,
  name,
}) {
  const { isMobileLayout } = useDeviceWidth();
  return createPortal(
    isMobileLayout ? (
      <dialog
        open={isModalOpen}
        className="absolute size-full bottom-0 left-0 z-40 bg-shadow-800"
      >
        {hasToShowTutorial ? (
          <ModalTutorialOnboarding
            fitContent
            onClose={() => {
              setIsModalOpen(false);
              setHasToShowTutorial(false);
            }}
          />
        ) : (
          <div className="size-full flex flex-col justify-end items-end py-10 px-9">
            <div className="size-full flex flex-col items-center justify-center gap-8">
              <Hourglass />
              <div className="space-y-8 px-2">
                <TwTitle
                  className="whitespace-break-spaces leading-4 text-center"
                  text={
                    <Trans
                      i18n={i18n}
                      i18nKey="validateIdentity.exploreVpagTitle"
                      components={[<span className="text-grass-800" />]}
                      values={{
                        name,
                      }}
                    />
                  }
                />
                <TwText
                  classList="text-center"
                  size="xl"
                  text={i18n.t('validateIdentity.exploreVpagDescription')}
                />
              </div>
            </div>
            <TwButton
              label="Continuar"
              onClick={() => setHasToShowTutorial(true)}
            />
          </div>
        )}
        <button
          className="absolute top-0 right-0 m-4 text-grass-800 text-4xl z-50"
          onClick={() => {
            setIsModalOpen(false);
            setHasToShowTutorial(false);
          }}
          type="button"
        >
          &times;
        </button>
      </dialog>
    ) : (
      <TwModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        classList="md:!h-fit md:!w-[800px] overflow-y-scroll md:overflow-y-hidden"
        classListContainer="w-80  mx-auto mt-20 max-h-[611px]  md:max-h-full md:my-auto  md:!w-full overflow-y-scroll md:overflow-y-hidden"
      >
        <ModalTutorialOnboarding onClose={setIsModalOpen} />
      </TwModal>
    ),
    document.body,
  );
}

export default Portal;
