import React, { useState } from 'react';
import PropTypes from 'prop-types';

import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import MerchanDisc from '@/components/atoms/MerchanDisco';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { STATUS_AFFILIATE } from '@/helpers/constants';
import IconVplus from '@/components/molecules/IconVplus';

import TwText from '@/components/v2/atoms/TwText';
import './styles.css';

const SLIDES_PER_VIEW = 3;
const SPACE_BETWEEN = 30;
const ONE = 1;
const TWO = 2;
const ZERO = 0;

function CarouselMech({ data = [], setSelectedMerchant, handleClickItem }) {
  const [currentIndexItem, setCurrentIndexItem] = useState(0);
  const quantityItens = data.length;
  const { isMobileLayout } = useDeviceWidth();
  const hasOnlyTwoItems = quantityItens === TWO && !isMobileLayout;
  return (
    <>
      <Swiper
        className={`h-[400px] w-1/2 lg:w-full ${
          hasOnlyTwoItems ? 'py-0 px-[120px]' : 'p-auto'
        }`}
        navigation={quantityItens !== ONE}
        centeredSlides
        slidesPerView={
          isMobileLayout
            ? ONE
            : quantityItens < SLIDES_PER_VIEW
            ? quantityItens
            : SLIDES_PER_VIEW
        }
        spaceBetween={SPACE_BETWEEN}
        onRealIndexChange={(e) => {
          setCurrentIndexItem(e.realIndex);
          setSelectedMerchant(data[e.realIndex]);
        }}
        isMobileLayout={isMobileLayout}
        onlyTwoItems={hasOnlyTwoItems}
      >
        {data.map(
          ({ id, image_url, name, user_vplus_image_url, usernames }, index) => {
            const isSelected = index === currentIndexItem;
            const isAffiliate =
              STATUS_AFFILIATE === usernames[ZERO]?.affiliate_status;
            const isBlocked =
              usernames[ZERO]?.cashback_blocked === undefined
                ? false
                : usernames[ZERO]?.cashback_blocked;
            return (
              <SwiperSlide
                isSelected={isSelected}
                key={id}
                className={`flex items-center flex-col h-fit justify-center gap-[20px] ${
                  isSelected ? 'my-14 mx-auto' : 'm-auto'
                }`}
              >
                <>
                  <MerchanDisc
                    isSelected={isSelected}
                    src={
                      isAffiliate
                        ? user_vplus_image_url || image_url
                        : image_url
                    }
                    onClick={() => (isSelected ? handleClickItem() : null)}
                    isBlocked={isBlocked}
                  />
                  {isSelected && (
                    <div
                      isBlocked={isBlocked}
                      className={`flex gap-[0.625rem] items-start ${
                        isBlocked ? 'opacity-50' : 'opacity-100'
                      }`}
                    >
                      <TwText
                        text={name}
                        classList="!text-[32px] leading-[41px]"
                        font="arboriaBold"
                      />
                      {isAffiliate && <IconVplus />}
                    </div>
                  )}
                </>
              </SwiperSlide>
            );
          },
        )}
      </Swiper>
    </>
  );
}
CarouselMech.propTypes = {
  data: PropTypes.array,
  setSelectedMerchant: PropTypes.func,
  handleClickItem: PropTypes.func,
}.isRequired;

export default CarouselMech;
