import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PiCheck } from 'react-icons/pi';

import toast from 'react-hot-toast';
import TwTitle from '@/components/atoms/TwTitle';
import TwStepper from '@/components/atoms/TwStepper';
import TwText from '@/components/v2/atoms/TwText';
import TwQrCode from '@/components/atoms/TwQrCode';
import TwButton from '@/components/atoms/Buttons/TwButton';
import TwTextButton from '@/components/atoms/Buttons/TwTextButton';
import { ReactComponent as Copy } from '@/assets/document-copy.svg';
import { ocrValidation } from '@/services/api';
import i18n from '@/translate/i18n';
import { TwModal } from '@/components/v2/atoms/TwModal';
import { ModalTutorialOnboarding } from '@/components/v2/Molecules/ModalTutorialOnboarding';

const STEPS_LABELS = [
  'Fotografe o documento',
  'Tire uma selfie',
  'Confirme os dados',
];

function CafValidation({ renderIframe }) {
  const [url, setUrl] = useState('');
  const [copiedUrl, setCopiedUrl] = useState(false);
  const [openTutorialModal, setOpenTutorialModal] = useState(false);

  const clipboard = () => {
    navigator.clipboard.writeText(url);
    if (!copiedUrl) {
      setCopiedUrl(true);
      toast.success(i18n.t('actions.copy'));
      setTimeout(() => setCopiedUrl(false), 2000);
    }
  };

  const validateUser = async () => {
    try {
      await ocrValidation().then((res) => setUrl(res.data.url));
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseModalTutorial = () => {
    setOpenTutorialModal(false);
  };

  useEffect(() => {
    validateUser();
  }, []);

  return (
    <section className="px-9 md:py-11 w-full bg-black">
      <TwTitle
        text="Termine a validação da sua identidade!"
        classList="text-left text-[28px] md:text-[34px] md:text-center text-pretty"
      />
      <TwStepper index="2" steps="3" labels={STEPS_LABELS} />
      <div>
        <div className="w-80 m-auto hidden md:flex flex-col gap-2 pt-10">
          <TwText size="xl" text={i18n.t('cafValidation.scannerQrCode')} />
          <div className="py-3">
            <TwQrCode className="size-56" url={url} />
          </div>
          <div className="flex justify-center items-center gap-3 py-3">
            <div className="w-10 h-[1px] bg-[#B6B6B6]" />
            <TwText text={i18n.t('cafValidation.orInsertURL')} />
            <div className="w-10 h-[1px] bg-[#B6B6B6]" />
          </div>
        </div>
        <div className="w-full max-w-[600px] m-auto pt-4 hidden md:block">
          <button
            disabled={copiedUrl}
            className="w-full h-[44px] text-ellipsis overflow-hidden bg-shadow-700 text-white text-base flex items-center justify-center relative disabled:bg-shadow-800 disabled:text-ashes-500"
            onClick={clipboard}
            type="button"
          >
            {url.slice(0, url.length / 1.6)}
            <div className="absolute right-0 px-3">
              {copiedUrl ? (
                <PiCheck className="size-5 text-ashes-500" />
              ) : (
                <Copy />
              )}
            </div>
          </button>
          <TwTextButton
            label="Conheca a Vpag"
            onClick={() => setOpenTutorialModal(true)}
          />
          <div classList="hidden md:block my-3 ">
            <TwText
              size="sm"
              classList="whitespace-nowrap"
              text={i18n.t('cafValidation.certifyDocumentIsValid')}
            />
            <TwText
              size="sm"
              classList="whitespace-nowrap"
              text={i18n.t('cafValidation.youCamPresentDocuments')}
            />
            <TwText
              size="sm"
              classList="whitespace-nowrap"
              text={i18n.t('cafValidation.digitalIDAvailable')}
            />
          </div>
        </div>
      </div>

      <div className="md:w-1/3 m-auto text-left md:text-center block md:hidden">
        <div className="w-full flex flex-col">
          <TwText text="Nossas dicas" color="grass-800" classList="pb-4" />
          <div className="flex flex-col gap-4 py-5 border-t-2 border-ashes-700">
            <TwText
              size="xl"
              text={i18n.t('cafValidation.lookForGoodLighting')}
            />
            <TwText
              size="xl"
              text={i18n.t('cafValidation.makeSureDocumentIsValid')}
            />
            <TwText
              size="xl"
              text={i18n.t('cafValidation.youCanPresentTraditionalID')}
            />
          </div>
          <div className="flex flex-col items-center justify-center gap-2 w-full h-36">
            <TwButton
              label="Validar"
              classList="md:h-2"
              onClick={renderIframe}
            />
            <TwTextButton
              label={i18n.t('cafValidation.getToKnowVpag')}
              onClick={() => setOpenTutorialModal(true)}
            />
          </div>
        </div>
        <TwText
          classList="hidden md:block"
          text={i18n.t('cafValidation.availableInSomeStates')}
        />
      </div>
      <TwModal
        isOpen={openTutorialModal}
        onClose={handleCloseModalTutorial}
        classList="md:!h-fit md:!w-[800px] overflow-y-scroll md:overflow-y-hidden"
        classListContainer="w-80  mx-auto mt-20 max-h-[611px]  md:max-h-full md:my-auto  md:!w-full overflow-y-scroll md:overflow-y-hidden"
      >
        <ModalTutorialOnboarding onClose={handleCloseModalTutorial} />
      </TwModal>
    </section>
  );
}

CafValidation.propTypes = {
  renderIframe: PropTypes.func,
}.isRequired;

export default CafValidation;
