import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Trans } from 'react-i18next';

import MainTemplate from '@/components/templates/MainTemplate';

import i18n from '@/translate/i18n';

import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import BackButton from '@/components/atoms/BackButton';
import CurrentBalance from '@/components/molecules/CurrentBalance';
import { getListMerchantsCashback } from '@/services/api';

import CashbackTutorialModal from '@/components/organisms/CashbackTutorialModal';
import TwTitle from '@/components/atoms/TwTitle';
import {
  ButtonActiveKey,
  ContainerContent,
  WelcomingImage,
  ParagraphStyled,
  ButtonsContainer,
} from './styles';

const ZERO = 0;

function Cashback() {
  const history = useHistory();
  const { isMobileLayout } = useDeviceWidth();
  const [activesMerchants, setActivesMerchants] = useState([]);
  const [showModalTutorial, setShowModalTutorial] = useState(false);
  const [hasInactivatedMerchants, setHasInactivatedMerchants] = useState(false);
  const [hasActivatedMerchants, setHasActivatedMerchants] = useState(false);

  const handleCloseModalTutorial = () => {
    setShowModalTutorial(false);
  };

  const handleNextPage = () => {
    history.push('/cashback/merchants');
  };
  const handleNextPageMarchantActives = () => {
    history.push('/cashback/merchants-actives');
  };

  const getActivesMerchants = async () => {
    const res = await getListMerchantsCashback();
    const { activeItems, inactivatedItems } = res.data.reduce(
      (acc, item) => {
        const isActive = item.usernames[ZERO]?.cashback_active_by_customer;
        if (isActive) {
          acc.activeItems.push(item);
        } else {
          acc.inactivatedItems.push(item);
        }
        return acc;
      },
      { activeItems: [], inactivatedItems: [] },
    );

    setActivesMerchants(activeItems);
    setHasActivatedMerchants(activeItems.length > ZERO);
    setHasInactivatedMerchants(inactivatedItems.length > ZERO);
    return { activesMerchants };
  };

  useEffect(async () => {
    await getActivesMerchants();
  }, []);

  return (
    <MainTemplate
      containerProps={{ gradient: true }}
      headerProps={{ mobileFullVersion: false }}
    >
      <ContainerContent isMobileLayout={isMobileLayout}>
        <header className="flex w-full px-5 relative">
          <div className="block md:hidden z-10">
            <BackButton to="/wallet" />
          </div>
          <div className="w-full md:pt-5">
            <div className="w-full absolute md:relative top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <TwTitle
                size={isMobileLayout ? 'm_lg' : 'd_lg'}
                font="arboriaBold"
                classList="text-center py-[7vh]"
              >
                {i18n.t('cashback.title')}
              </TwTitle>
            </div>
          </div>
        </header>
        {isMobileLayout && <CurrentBalance />}
        <div className="flex flex-col items-center justify-center px-[17rem] md:px-[26rem]">
          <WelcomingImage className="mask-image-top" />
          <p
            color="light"
            size={isMobileLayout ? 'small' : 'medium'}
            family="primary"
            textAlign="center"
            lineHeight="xLarge"
            className=" leading-8 text-[18px] md:text-xl text-white"
          >
            {activesMerchants.length > ZERO
              ? i18n.t('cashback.descriptionTwo')
              : i18n.t('cashback.descriptionOne')}
          </p>
        </div>
        <ButtonsContainer isMobileLayout={isMobileLayout} className="w-full">
          {hasInactivatedMerchants && (
            <ButtonActiveKey
              onClick={handleNextPage}
              className="w-full md:w-56 !text-xl !leading-none"
            >
              {hasActivatedMerchants
                ? i18n.t('cashback.keysForActive')
                : i18n.t('cashback.activeKeyPix')}
            </ButtonActiveKey>
          )}
          {hasActivatedMerchants && (
            <ButtonActiveKey
              bgColor="blue"
              onClick={handleNextPageMarchantActives}
              className="w-full  md:w-56 !text-xl"
            >
              {i18n.t('actions.activated')}
            </ButtonActiveKey>
          )}
        </ButtonsContainer>
        <div className="pb-5">
          <ParagraphStyled
            color="light"
            size={isMobileLayout ? 'small' : 'medium'}
            family="primary"
            textAlign="start"
            lineHeight="xLarge"
            className="!text-[18px] md:!text-xl"
          >
            <Trans
              i18n={i18n}
              i18nKey="cashback.clickHere"
              components={[
                <TextButton
                  display="inline"
                  type="button"
                  id="signup-terms-and-conditions-btn"
                  size={isMobileLayout ? 'small' : 'medium'}
                  onClick={() => setShowModalTutorial(true)}
                />,
              ]}
            />
          </ParagraphStyled>
        </div>
      </ContainerContent>

      <CashbackTutorialModal
        handleClose={handleCloseModalTutorial}
        showModal={showModalTutorial}
      />
    </MainTemplate>
  );
}

export default Cashback;
