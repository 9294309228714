import React from 'react';
import PropTypes from 'prop-types';

import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';

import i18n from '@/translate/i18n';

import KycError from '@/components/organisms/KycError';
import KycQrValidation from '@/components/organisms/KycQrValidation';
import { useUserData } from '@/context/userContext';

const STATUS_FAILED = 3;

function RegisterValidationErrorMessage({ restartValidation }) {
  const { userData } = useUserData();
  const blockingValidations = [
    'over_18',
    'has_no_sanctions',
    'cpf_has_not_dead',
    'cpf_error_code',
    'cpf_query',
    'valid_cpf_number',
    'has_cpf',
    'parameters_and_document_are_equal',
    'ocr_equals_parameters',
    'parameters_and_document_undefined_or_equal',
    'cpf_equal_name',
  ];

  const revalidateValidations = [
    'documentscopy_approved',
    'facematch_has_selfie_photo',
    'facematch_is_equal',
    'is_readable',
  ];
  const errorSlug =
    blockingValidations.find((str) =>
      userData.details.caf_callback_error.includes(str),
    ) ||
    (userData?.status === STATUS_FAILED
      ? 'has_no_sanctions'
      : 'cpf_has_not_dead');
  const revalidate = revalidateValidations.find((str) =>
    userData.details.caf_callback_error.includes(str),
  );

  return (
    <>
      {userData?.status === STATUS_FAILED && revalidate ? (
        <KycQrValidation
          restartValidation={restartValidation}
          errorSlug={revalidate}
        />
      ) : errorSlug ? (
        <KycError errorSlug={errorSlug} />
      ) : (
        <div>
          {restartValidation && (
            <TextButton type="button" onClick={restartValidation}>
              {i18n.t('registerValidationErrorMessage.resendDocs')}
            </TextButton>
          )}
        </div>
      )}
    </>
  );
}

RegisterValidationErrorMessage.propTypes = {
  restartValidation: PropTypes.func,
};

export default RegisterValidationErrorMessage;
