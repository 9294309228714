import styled, { css } from 'styled-components';
import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';

const ContainerStyles = css`
  align-items: center;
  animation: goUpForm 0.4s;
  background-color: transparent;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  width: 100%;
`;

export const Container = styled.div`
  ${ContainerStyles}
`;

export const LogoutButton = styled(TextButton)`
  margin: 2vh auto 0;
  width: 100%;
`;
