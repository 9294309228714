import styled, { css } from 'styled-components';

export const ContainerCashbackPix = styled.div`
  ${({ isMobileLayout }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${isMobileLayout ? '10px' : '30px'};
    height: ${isMobileLayout ? '100vh' : '621px'};
    overflow-y: auto;
    width: ${isMobileLayout ? '100vw' : '100%'};
    @media (max-width: 320px) and (max-height: 568px) {
      height: auto;
      padding-bottom: 1rem;
    }
  `}
`;
export const CashBackPixBody = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 30px;
  height: 100%;
  margin: auto 42px;
`;
export const OptionsCashbackContainer = styled.div`
  display: flex;
`;
export const ParagraphContainer = styled.div`
  padding: 0 5px;
`;
