import React, { useState } from 'react';
import TwText from '../TwText';
import useDeviceWidth from '@/hooks/useDeviceWidth';

const ToggleBetweenOptions = ({ options, onToggle }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]?.title);
  const { isMobileLayout } = useDeviceWidth();

  const handleToggle = (option) => {
    setSelectedOption(option.title);
    onToggle(option.title);
  };

  return (
    <div className="rounded-3xl md:rounded-[86px] w-72 h-11 md:h-[72px] md:w-full border border-gray-500 grid grid-cols-2 overflow-hidden">
      {options?.map((option) => {
        const isSelected = selectedOption === option.title;
        const desktopIconOption = !isMobileLayout && option.iconActive;
        const currentIcon =
          desktopIconOption ||
          (isSelected && option.iconActive) ||
          (!isSelected && option.iconInactive);

        return (
          <button
            key={option.title}
            className={`border-none rounded-3xl md:rounded-[86px] md:flex md:justify-start md:gap-3 md:p-4 text-white flex items-center justify-center ${
              isSelected ? 'bg-[#75AAE4]' : ''
            }`}
            type="button"
            onClick={() => handleToggle(option)}
          >
            <img
              src={currentIcon}
              alt={`${option.title} icon`}
              className={`size-6 md:size-9 ${
                !isMobileLayout ? 'opacity-50' : ''
              }`}
            />
            <TwText
              classList="hidden md:block"
              size="xl"
              font="arboriaBold"
              text={option.titleDisplay}
            />
          </button>
        );
      })}
    </div>
  );
};

export default ToggleBetweenOptions;
