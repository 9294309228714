import styled, { css } from 'styled-components';
import { BannerCashbackBody } from '../CashbackPixInfo/style';

export const CashbackResumeBody = styled(BannerCashbackBody)`
  ${() => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 50px;
    overflow-y: auto;
  `}
`;
export const DetailContainer = styled.div`
  ${({ isMobileLayout }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${isMobileLayout ? '1.875rem' : '1.125rem'};
    width: 100%;
    @media (max-width: 380px) and (max-height: 668px) {
      gap: 5px;
    }
  `}
`;
export const Divider = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider.light};
  opacity: 0.3;
  width: 100%;
`;
