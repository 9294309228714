import React, { useState } from 'react';

import MainTemplate from '@/components/templates/MainTemplate';
import Modal from '@/components/molecules/Modal';
import SectionDivider from '@/components/atoms/SectionDivider/styles';
import VPlugStatus from '@/components/organisms/VPlusStatus';
import VPlusAffiliationStatus from '@/components/molecules/Modal/Messages/VPlusAffiliationStatus';
import VPlusClubInfo from '@/components/organisms/VPlusClubInfo';
import WalletButton from '@/components/atoms/Buttons/WalletButton';
import i18n from '@/translate/i18n';
import { ReactComponent as InfoIcon } from '@/assets/infoIcon.svg';
import { ReactComponent as StatusIcon } from '@/assets/statusIcon.svg';

import { ContentWrapper, Section, SwitchButtonWrapper } from './styles';

function ClubVPlus() {
  const [showInfo, setShowInfo] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState({});

  return (
    <MainTemplate
      headerProps={{ returnTo: '/wallet', title: i18n.t('clubVPlus.title') }}
    >
      <ContentWrapper>
        <Section showDetails={showInfo}>
          <VPlugStatus
            setSelectedStatus={setSelectedStatus}
            selectedStatus={selectedStatus}
          />
        </Section>
        <SectionDivider height="90%" />
        <Section showDetails={showInfo} animationReverse>
          <VPlusClubInfo />
        </Section>
      </ContentWrapper>
      <SwitchButtonWrapper>
        <WalletButton
          content={i18n.t('clubVPlus.status')}
          selected={!showInfo}
          onClick={() => setShowInfo(false)}
          icon={StatusIcon}
        />
        <WalletButton
          content={i18n.t('clubVPlus.info')}
          selected={showInfo}
          onClick={() => setShowInfo(true)}
          icon={InfoIcon}
        />
      </SwitchButtonWrapper>
      <Modal
        showModal={Boolean(Object.keys(selectedStatus).length)}
        handleClose={() => setSelectedStatus({})}
        height={selectedStatus?.status === 'affiliated' ? '300px' : '200px'}
      >
        <VPlusAffiliationStatus affiliationStatus={selectedStatus} />
      </Modal>
    </MainTemplate>
  );
}

export default ClubVPlus;
