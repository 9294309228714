import styled, { css } from 'styled-components';

import { hexToRGBA } from '@/styles/colors';
import { InternalButton } from '@/components/atoms/Buttons/Button/styles';

export const Container = styled.header`
  align-items: center;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex-direction: column;
  max-height: 390px;
  min-height: ${({ mobileFullVersion }) =>
    mobileFullVersion ? '220px' : '100px'};
  position: relative;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    background-color: ${({ theme, background }) =>
      background || hexToRGBA(theme.colors.shadow, 0.1)};
    display: ${({ hideInDesktop }) => (hideInDesktop ? 'none' : 'flex')};
    flex-direction: row;
    max-height: 100px;
    min-height: 80px;
    min-width: 100%;
    padding-left: 1rem;
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: row;
    min-width: 100%;
  }
`;

export const Title = styled.h1`
  ${({ theme, fontSize = 'auto', titleColor }) => css`
    align-items: center;
    color: ${titleColor || theme.colors.primary};
    font-weight: 400;
    font: ${theme.font.size.medium} ${theme.font.family.secondary};
    min-width: 100px;
    text-align: center;
    font-size: ${fontSize};

    svg {
      margin-left: 10px;
    }
  `}
`;

export const ReturnWrapper = styled.div`
  ${({ theme, color, hideInMobile, amountHighlighted }) => css`
    align-items: center;
    align-self: start;
    color: ${color ? theme.colors[color] : theme.colors.info.light};
    display: ${hideInMobile ? 'none' : 'flex'};
    justify-content: center;
    padding-top: 2rem;
    position: relative;
    width: 100%;

    p {
      color: ${color ? theme.colors[color] : theme.colors.info.title};
      font: ${theme.font.size.medium} ${theme.font.family.primary};
      margin-top: 1rem;

      span {
        color: ${amountHighlighted ? theme.colors.primary : 'inherit'};
        font-family: ${theme.font.family.secondary};
      }
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      display: none;
    }
  `}
`;

export const ReverseWrapper = styled.div`
  align-items: center;
  display: ${({ mobileFullVersion }) => (mobileFullVersion ? 'flex' : 'none')};
  flex-direction: column;
  flex-direction: row-reverse;
  gap: 1rem;
  justify-content: space-between;
  width: 90%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    gap: 0;
    justify-content: space-between;
    margin-left: 1.5rem;
    width: 100%;
  }
`;

export const ChildrenWrapper = styled.div`
  position: absolute;
  right: 25vw;
  top: 20px;
`;

export const CustomButtonWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 1.2rem;
  z-index: 9;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const FloatingButton = styled(InternalButton)`
  display: ${({ showInMobileMode }) => (showInMobileMode ? 'flex' : 'none')};
  margin-top: 1rem;
  padding: 0 1rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: flex;
    left: 250px;
    margin: 0;
    position: absolute;
  }
`;
