import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import io from 'socket.io-client';
import { Trans } from 'react-i18next';

import DepositDetailsSummary from '@/components/molecules/DepositDetailsSummary';
import DepositInstructions from '@/components/molecules/Modal/Messages/DepositInstructions';
import Modal from '@/components/molecules/Modal';
import SectionDivider from '@/components/atoms/SectionDivider/styles';
import TransactionDetailsSummary from '@/components/molecules/TransactionDetailsSummary';
import i18n from '@/translate/i18n';
import useBalance from '@/hooks/useBalance';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { InternalButton } from '@/components/atoms/Buttons/Button/styles';
import { LinkTextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { Paragraph } from '@/components/atoms/Typography/styles';
import TwTitle from '@/components/atoms/TwTitle';
import TwButton from '@/components/atoms/Buttons/TwButton';
import TwText from '@/components/v2/atoms/TwText';
import { useWalletData } from '@/context/walletContext';
import { formatCurrency, formatShortDate } from '@/helpers/stringFormat';
import { ReactComponent as ErrorInfo } from '@/assets/errorInfo.svg';

import {
  AmountResume,
  ButtonWrapper,
  ContentWrapper,
  HiddenWrapper,
  LinksWrapper,
  Section,
} from './styles';

const ICON_SIZE = '40px';
const MINIMUM_BALANCE = 10;

function DepositDetails({ data, setData }) {
  const history = useHistory();
  const [showInstruction, setShowInstruction] = useState(false);
  const { isMobileLayout } = useDeviceWidth();
  const { getBalance } = useBalance();
  const { walletBalance } = useWalletData();

  const totalAmount = data?.customer_bonus
    ? Number(data.customer_bonus) + Number(data.customer_amount)
    : data.customer_amount;

  const currentBalanceBiggerOrEqualThanTen =
    (!walletBalance && true) || walletBalance >= MINIMUM_BALANCE;

  const detailsData = {
    method: data?.payment_method_name ?? '',
    id_transaction: data?.id,
    dueDate: data?.details?.expires_at
      ? formatShortDate(data.details.expires_at)
      : '-',
    amount: formatCurrency(
      data?.customer_amount ?? 0,
      data?.customer_currency_iso,
    ),
    bonus: data?.customer_bonus
      ? formatCurrency(data?.customer_bonus ?? 0, data?.customer_currency_iso)
      : null,
    [`youReceive.${data?.status}`]: formatCurrency(
      totalAmount,
      data?.customer_currency_iso,
    ),
  };
  const isPending = data?.status === 'pending';

  useEffect(() => {
    let socket = null;
    if (isPending) {
      socket = io(process.env.REACT_APP_WEB_SOCKET_SERVER_URL, {
        path: '/websocket/socket.io',
      });
      socket.on('confirmTransaction', async (socketData) => {
        if (socketData?.transaction_id === Number(data?.id)) {
          await getBalance();
          setData((state) => ({ ...state, status: socketData?.status }));
          socket.disconnect();
        }
      });
    }
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);
  const pendingTransferApi = localStorage.getItem('pendingApiTransaction');

  const currentInternalButton = () => {
    if (currentBalanceBiggerOrEqualThanTen) {
      return (
        <InternalButton
          id="deposit-details-transfer-btn"
          to="/transfer"
          onClick={() => {
            history.push(
              pendingTransferApi
                ? `/transfer-api/${pendingTransferApi}`
                : '/transfer',
            );
          }}
        >
          {i18n.t('transactionDetails.transferBalance')}
        </InternalButton>
      );
    }
    return (
      <TwButton
        onClick={() => history.push('/deposit')}
        classList="w-[210px] h-[40px] !text-base font-semibold !rounded"
        label={i18n.t('transactionDetails.doOtherDeposit')}
      />
    );
  };

  return (
    <ContentWrapper>
      <Section>
        {isMobileLayout && (
          <HiddenWrapper hideInDesktop>
            <DepositDetailsSummary data={data} />
          </HiddenWrapper>
        )}
        <TransactionDetailsSummary
          data={detailsData}
          bonus={data?.bonus}
          status={data?.status}
          hideTitle={isMobileLayout}
        />

        <LinksWrapper hideInMobile>
          {isPending ? (
            <LinkTextButton
              className="!text-lg"
              to="#"
              onClick={setShowInstruction}
              id="deposit-pending-instructions-btn"
            >
              {i18n.t('generatePix.instructions')}
            </LinkTextButton>
          ) : (
            <LinkTextButton
              className="!text-lg"
              to="/wallet"
              id="deposit-details-return-btn"
            >
              {i18n.t('transactionDetails.return')}
            </LinkTextButton>
          )}
        </LinksWrapper>
      </Section>
      <SectionDivider height="90%" />
      <Section>
        <HiddenWrapper hideInMobile>
          <DepositDetailsSummary data={data} />
        </HiddenWrapper>

        <AmountResume>
          <TwTitle
            size={isMobileLayout ? 'm_md' : 'd_md'}
            font="arboriaBold"
            classList="text-center"
          >
            {i18n.t(`transactionDetails.amountResume.deposit.${data?.status}`)}
          </TwTitle>
          <strong>
            {formatCurrency(totalAmount, data?.customer_currency_iso)}
          </strong>
        </AmountResume>
        {isPending && (
          <Paragraph textAlign="center">
            <Trans
              i18n={i18n}
              i18nKey="transactionDetails.depositPendingWarning"
              components={[<span />]}
            />
          </Paragraph>
        )}
        {data?.status === 'completed' ? (
          <ButtonWrapper>
            {currentInternalButton()}
            <LinkTextButton className="!text-lg" to="/transactions">
              {i18n.t('transactionDetails.goToHistory')}
            </LinkTextButton>
          </ButtonWrapper>
        ) : data?.status === 'cancelled' ? (
          data?.cancel_reason === 'psp-payer-document' ? (
            <>
              <div className="flex items-center">
                <ErrorInfo height={ICON_SIZE} width={ICON_SIZE} />
                <TwText
                  size="xl"
                  color="crem"
                  text={i18n.t('deposit.cpfError')}
                  classList="ml-3 max-w-56 text-left"
                />
              </div>
              <TwButton
                height="secondary"
                font="arboria-bold"
                label={i18n.t('deposit.deposit')}
                classList="w-[210px]"
                onClick={() => history.push('/deposit')}
              />
            </>
          ) : (
            <LinkTextButton className="!text-lg" to="/transactions">
              {i18n.t('transactionDetails.goToHistory')}
            </LinkTextButton>
          )
        ) : (
          <LinksWrapper showInMobile>
            <LinkTextButton
              className="!text-lg"
              to="/transactions"
              id="deposit-details-view-history"
            >
              {i18n.t('transactionDetails.goToHistory')}
            </LinkTextButton>
          </LinksWrapper>
        )}
      </Section>

      <Modal
        button={i18n.t('modal.understood')}
        height="300px"
        setShowModal={setShowInstruction}
        showModal={showInstruction}
      >
        <DepositInstructions />
      </Modal>
    </ContentWrapper>
  );
}

DepositDetails.propTypes = {
  setData: PropTypes.func,
  reloadAction: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.number,
    details: PropTypes.shape({
      psp_amount: PropTypes.number,
      expires_at: PropTypes.date,
      psp_payment_info: PropTypes.shape({
        qr_code: PropTypes.string,
      }),
    }),
  }),
}.isRequired;

export default DepositDetails;
