import styled, { css } from 'styled-components';

import { Button } from '@/components/atoms/Buttons/Button/styles';

export const SubmitPrimary = styled(Button)`
  background-color: ${({ color = 'primary', theme, disabledMaxWidth }) =>
    theme.colors.button[color]};
  border: none;
  color: ${({ color, theme }) => color && theme.colors.info.dark};
  font: ${({ theme }) =>
    `${theme.font.size.small} ${theme.font.family.secondary}`};
  height: 7vh;
  margin-bottom: 0;
  max-height: 45px;
  min-height: 35px;
  min-width: 155px;
  opacity: ${({ opac }) => (opac ? '0.4' : '1')};
  width: ${({ width }) => width || '60%'};

  ${({ disabledMaxWidth }) =>
    disabledMaxWidth &&
    css`
      max-width: none;
    `};

  &:disabled {
    background-color: ${({ theme }) => theme.colors.button.disabled};
    cursor: not-allowed;
    opacity: 1;
    font-family: ${({ theme }) => theme.font.family.primary};
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: ${({ width }) => width || '44%'};
  }
`;

export const SubmitSecondary = styled(SubmitPrimary)`
  color: ${({ theme }) => theme.colors.primary};
  min-width: 100px;
`;
