import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { PiCheck } from 'react-icons/pi';
import TwQrCode from '@/components/atoms/TwQrCode';
import i18n from '@/translate/i18n';
import { ReactComponent as ScanIcon } from '@/assets/Kyc/scan.svg';
import { ReactComponent as FingerScan } from '@/assets/Kyc/finger-scan.svg';
import { ReactComponent as Copy } from '@/assets/document-copy.svg';
import { ReactComponent as Check } from '@/assets/checked.svg';
import TwButton from '@/components/atoms/Buttons/TwButton';
import { ocrValidation } from '@/services/api';

const HALF_URL_LENGTH = 2;
const TWO_SECONDS = 2000;

function KycQrValidation({ restartValidation, errorSlug }) {
  const [url, setUrl] = useState('google');
  const [copiedUrl, setCopiedUrl] = useState(false);
  const isFacematchError = errorSlug?.includes('facematch');
  const instructions = i18n.t(
    isFacematchError
      ? 'cafValidation.revalidate.facematchInstructions'
      : 'cafValidation.revalidate.instructions',
    {
      returnObjects: true,
    },
  );

  const clipboard = () => {
    navigator.clipboard.writeText(url);
    if (!copiedUrl) {
      setCopiedUrl(true);
      toast.success(i18n.t('actions.copy'));
      setTimeout(() => setCopiedUrl(false), TWO_SECONDS);
    }
  };

  const validateUser = async () => {
    try {
      await ocrValidation().then((res) => setUrl(res.data.url));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    validateUser();
  }, []);

  return (
    <main className="flex justify-center items-center gap-10 w-full h-[300px] lg:max-h-[100%]">
      <div className="hidden pt-20 lg:flex w-[80%]">
        <section
          className={`text-left grid grid-cols-[20%_100%] w-1/2 ${
            isFacematchError ? 'items-center' : ''
          }`}
        >
          <div
            className={`size-full ${
              isFacematchError ? 'pt-16' : 'pt-1'
            } flex justify-start`}
          >
            {isFacematchError ? (
              <FingerScan className="size-16" />
            ) : (
              <ScanIcon className="size-16" />
            )}
          </div>
          <div className="flex flex-col gap-8 max-w-[100%]">
            <h1 className="text-white text-2xl font-semibold pr-32 whitespace-pre">
              {i18n.t(
                isFacematchError
                  ? 'cafValidation.revalidate.facematchTitle'
                  : 'cafValidation.revalidate.title',
              )}
            </h1>
            <p className="text-white text-xl">
              {i18n.t('cafValidation.revalidate.subtitle')}
            </p>
            <ul className="flex flex-col gap-2">
              {instructions.map((item) => (
                <li
                  className="text-white text-base leading-8 relative flex max-w-[400px]"
                  key={item}
                >
                  <div className="relative ">
                    <Check className="absolute -left-10  mt-3 size-6" />
                    <p>{item}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <section className="flex flex-col gap-4 w-[55%]">
          <p className="text-white text-base  max-w-72 m-auto">
            {i18n.t('cafValidation.revalidate.cameraInstruction')}
          </p>
          <TwQrCode url={url} className="size-72" />
          <div className="flex justify-center items-center gap-3 py-3">
            <div className="w-8 h-[1px] bg-[#B6B6B6]" />
            <p className="text-white text-base">
              {i18n.t('cafValidation.revalidate.typingInstruction')}
            </p>
            <div className="w-8 h-[1px] bg-[#B6B6B6]" />
          </div>
          <button
            disabled={copiedUrl}
            className="w-full m-auto h-[44px] max-w-72 text-ellipsis overflow-hidden bg-shadow-700 text-white text-base flex items-center justify-center relative disabled:bg-shadow-800 disabled:text-ashes-500"
            onClick={clipboard}
            type="button"
          >
            {url.slice(0, url.length / HALF_URL_LENGTH)}
            <div className="absolute right-0 px-3">
              {copiedUrl ? (
                <PiCheck className="size-5 text-ashes-500" />
              ) : (
                <Copy />
              )}
            </div>
          </button>
          <div className="w-[55%] m-auto">
            <p className="text-white">
              {i18n.t('cafValidation.revalidate.note')}
            </p>
          </div>
        </section>
      </div>
      <div className="lg:hidden h-screen flex flex-col items-center justify-center gap-10 px-8 py-2 iphone-se:mt-44 mt-10">
        <div className="space-y-9">
          {isFacematchError ? (
            <FingerScan className="size-20 m-auto" />
          ) : (
            <ScanIcon className="size-20 m-auto" />
          )}
          <h1 className="text-white text-3xl mt-4">
            {i18n.t(
              isFacematchError
                ? 'cafValidation.revalidate.facematchTitle'
                : 'cafValidation.revalidate.title',
            )}
          </h1>
          <p className="text-white text-lg">
            {i18n.t(
              isFacematchError
                ? 'cafValidation.revalidate.facematchDescription'
                : 'cafValidation.revalidate.description',
            )}
          </p>
        </div>
        <div className="w-full space-y-6 h-8 pt-[20%] md:pt-0 max-h-568:pt-10">
          <TwButton label="Tentar novamente" onClick={restartValidation} />
          <div>
            <a href="test" className="text-grass-800 underline text-lg pb-10">
              {i18n.t('cafValidation.support')}
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}

export default KycQrValidation;
