import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { hexToRGBA } from '@/styles/colors';

export const ContentWrapper = styled.div`
  ${({ theme }) => css`
    align-items: center;
    display: flex;
    flex: 0.8;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    & > div {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    @media screen and (min-width: ${theme.breakpoints.md}) {
      display: flex;
      height: 100%;
      justify-self: center;
      max-height: 550px;
      max-width: 500px;
      width: 450px;
    }
  `}
`;

export const ItemWrapper = styled.div`
  ${({ theme, showHover }) => css`
    color: ${theme.colors.info.light};
    display: flex;
    flex-direction: column;
    height: 50px;
    justify-content: center;
    padding: 2rem 0.3rem;
    position: relative;
    width: 87%;

    &:not(:last-child) {
      border-bottom: ${`1px solid ${theme.colors.bgGradient.start}`};
    }

    &:hover {
      background: ${showHover
        ? `radial-gradient(
        circle,
        ${hexToRGBA(theme.colors.info.gray, 0.05)} 0%,
        ${hexToRGBA(theme.colors.info.gray, 0.5)} 0%,
        ${hexToRGBA(theme.colors.info.gray, 0.05)} 100%
      )`
        : 'none'};
      cursor: ${showHover && 'pointer'};
    }

    @media screen and (min-width: ${theme.breakpoints.md}) {
      max-width: 400px;
    }

    @media screen and (min-width: ${theme.breakpoints.xl}) {
      height: 80px;
    }

    p {
      align-items: center;
      display: flex;
    }
  `}
`;

export const Span = styled.span`
  color: ${({ theme, color }) => theme.colors[color ?? 'primary']};
  font-family: ${({ theme }) => theme.font.family.primary};
  margin-right: 10px;
`;

export const ButtonsWrapper = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
`;

export const ActionButton = styled(Link)`
  ${({ theme, hideInMobile }) => css`
    border: none;
    color: ${theme.colors.primary};
    display: ${hideInMobile && 'none'};
    font: ${`${theme.font.size.xSmall} ${theme.font.family.secondary}`};
    margin-right: 10px;
    padding: 1px 5px;
    text-decoration: underline;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      display: block;
    }
  `}
`;
