import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

const ButtonStyles = ({
  theme,
  bgColor = 'primary',
  fontSize = 'base',
  fontFamily = 'secondary',
}) => css`
  background-color: ${theme.colors[bgColor]};
  border: none;
  border-radius: 4px;
  color: ${theme.colors.info.dark};
  font: ${theme.font.size[fontSize]} ${theme.font.family[fontFamily]};
  height: 6vh;
  max-height: 40px;
  max-width: 280px;
  min-height: 35px;
  min-width: 210px;

  &:disabled {
    background-color: ${theme.colors.button.disabled};
    cursor: not-allowed;
    opacity: 1;
  }
`;

const LinkButtonStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;
  text-decoration: none;
`;

const LinkDisabledStyled = css`
  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'unset')};
`;

export const Button = styled.button`
  ${ButtonStyles}
`;

export const ExternalButton = styled.a`
  ${ButtonStyles}
  ${LinkButtonStyles}
  ${LinkDisabledStyled}
`;

export const InternalButton = styled(Link)`
  ${ButtonStyles}
  ${LinkButtonStyles}
  ${LinkDisabledStyled}
`;
