import React from 'react';

import TwTitle from '@/components/atoms/TwTitle';
import i18n from '@/translate/i18n';
import TwText from '@/components/v2/atoms/TwText';
import TwButton from '@/components/atoms/Buttons/TwButton';
import BackButton from '@/components/atoms/BackButton';
import { ReactComponent as ErrorIcon } from '@/assets/icon-error.svg';

function ErrorActiveCashback({ setErrorActiveCashback }) {
  const handleClearError = () => {
    setErrorActiveCashback('');
  };

  return (
    <div className="size-full flex flex-col justify-center items-center px-8 gap-y-12 ">
      <header className="w-full flex absolute top-0.5 items-center mt-10 md:hidden px-9">
        <div className="w-full mx-auto flex">
          <div className="block md:hidden">
            <BackButton onClick={handleClearError} />
          </div>
          <TwTitle
            text={i18n.t('cashback.title')}
            className="text-lg !text-grass-800 mx-auto "
          />
        </div>
      </header>
      <ErrorIcon />
      <div className="flex flex-col gap-y-2 md:w-[483px] mx-auto">
        <TwTitle
          font="arboriaBold"
          text={i18n.t('cashbackErrorActive.title')}
          className="text-[26px] w-full md:text-[32px]"
        />
        <TwText
          text={i18n.t('cashbackErrorActive.description')}
          size="lg"
          className="text-lg md:text-2xl"
        />
      </div>

      <TwButton
        label={i18n.t('actions.understood')}
        className="max-w-[320px] mx-auto !text-xl"
        font="arboria-bold"
        onClick={handleClearError}
      />
    </div>
  );
}

export default ErrorActiveCashback;
