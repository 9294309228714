import React from 'react';
import PropTypes from 'prop-types';
import HeadModal from '@/components/molecules/HeadModal';
import i18n from '@/translate/i18n';
import PersonPlus from '@/assets/personPlus.svg';
import { Container } from '../CashbackPixInfo/style';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { Title } from '@/components/atoms/Typography/styles';
import { ButtonHover } from '@/components/atoms/ButtonHover';

const SECOND_STEP = 2;

export const CashbackPixCreateAccount = ({
  handleBackStep,
  handleNextStep,
  merchantSelected,
}) => {
  const { isMobileLayout } = useDeviceWidth();
  return (
    <Container isMobile={isMobileLayout}>
      <HeadModal
        currentStep={SECOND_STEP}
        handleBackStep={handleBackStep}
        currentIcon={PersonPlus}
      />

      <div
        className={`flex flex-col items-center h-screen px-10 md:px-14 ${
          isMobileLayout ? 'gap-[3.75rem]' : 'gap-[1.875rem]'
        }`}
      >
        <div className="text-center">
          <Title size="medium" className="text-xl md:text-2xl text-white">
            {i18n.t('cashbackModalCreateAccount.titleOne')}
            {merchantSelected.name}
            {i18n.t('cashbackModalCreateAccount.titleTwo')}
          </Title>
        </div>
        <ButtonHover
          className="w-full"
          onClick={() => {
            window.open(merchantSelected.url, '_blank');
          }}
        >
          {i18n.t('cashbackModalCreateAccount.createAccountNow')}
        </ButtonHover>
        <div className="text-center">
          <p className="!font-extralight text-white text-xl">
            {i18n.t('cashbackModalCreateAccount.accountExist')}
            <span className="font-bold">{merchantSelected.name}</span>
            {i18n.t('cashbackModalCreateAccount.clickHere')}
            <span
              role="button"
              className="underline transition-opacity duration-200 hover:opacity-80 font-bold text-grass-800"
              onClick={() => handleNextStep()}
              onKeyDown={() => handleNextStep()}
              tabIndex={0}
            >
              {i18n.t('cashbackModalCreateAccount.Here')}
            </span>
          </p>
        </div>
      </div>
    </Container>
  );
};
CashbackPixCreateAccount.propTypes = {
  handleBackStep: PropTypes.func,
  handleNextStep: PropTypes.func,
  merchantSelected: PropTypes.object,
}.isRequired;
