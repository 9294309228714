import styled, { css } from 'styled-components';
import { Paragraph } from '@/components/atoms/Typography/styles';
import Subtitle from '@/components/atoms/Subtitle';
import { BannerCashbackBody } from '../CashbackPixInfo/style';

export const CashbackFinishedBody = styled(BannerCashbackBody)`
  ${({ isMobileLayout }) => css`
    padding: 0 15px;
    p {
      font-size: ${isMobileLayout ? '24px' : 'auto'};
      span {
        font-size: ${isMobileLayout ? '24px' : 'auto'};
      }
    }
  `};
`;
export const CashbackFinishedImage = styled.img`
  position: relative;
  z-index: 1;
`;
export const ParagraphStyled = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.light};
  max-height: 88px;
  text-align: center;
  span {
    font-weight: bolder;
  }
`;
export const ParagraphTwo = styled(ParagraphStyled)`
  font-size: 15px;
`;
export const SpanStyled = styled.span`
  color: ${({ theme }) => theme.colors.blue} !important;
`;
export const SubtitleStyled = styled(Subtitle)`
  width: 100%;
`;
