import i18n from '@/translate/i18n';

export const tourDeposit = [
  {
    target: '.deposit',
    title: `${i18n.t('tours.tourDeposit.stepClickDeposit.title')}`,
    content: `${i18n.t('tours.tourDeposit.stepClickDeposit.description')}`,
    showProgress: true,
    disableBeacon: true,
    placement: 'right',
    data: { next: '/deposit' },
  },
  {
    target: '.transaction',
    title: `${i18n.t('tours.tourDeposit.stepInsertValueTransaction.title')}`,
    content: `${i18n.t(
      'tours.tourDeposit.stepInsertValueTransaction.description',
    )}`,
    showProgress: true,
    disableBeacon: true,
    placement: 'top',
  },
  {
    target: '#deposit-btn-next',
    title: `${i18n.t('tours.tourDeposit.stepClickCTA.title')}`,
    content: `${i18n.t('tours.tourDeposit.stepClickCTA.description')}`,
    showProgress: true,
  },
  {
    target: '.details-deposit',
    title: `${i18n.t('tours.tourDeposit.stepSeeDatas.title')}`,
    content: `${i18n.t('tours.tourDeposit.stepSeeDatas.description')}`,
    showProgress: true,
    disableBeacon: true,
    placement: 'bottom',
  },
  {
    target: '#deposit-btn-confirm',
    title: `${i18n.t('tours.tourDeposit.stepClickConfirme.title')}`,
    content: `${i18n.t('tours.tourDeposit.stepClickConfirme.description')}`,
    showProgress: true,
  },
  {
    target: '#deposit-details-copy-pix-key-btn',
    title: `${i18n.t('tours.tourDeposit.stepClickCopyButton.title')}`,
    content: `${i18n.t('tours.tourDeposit.stepClickCopyButton.description')}`,
    showProgress: true,
    disableBeacon: true,
    placement: 'left-star',
  },
];
