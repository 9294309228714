import {
  POPTIN_SCRIPT_SRC,
  cpfRegex,
  registrationStatus,
} from '@/helpers/constants';

const VALIDATED = 'validated';
const COMPLETED = 'completed';
const REJECTED = 'rejected';

const getRouteByUserStatus = (status, currPath) => {
  let newPath = currPath;

  const isExternalFlow = currPath?.includes('/external');
  if (status === registrationStatus.validateEmail) {
    newPath = isExternalFlow ? '/external/validate-email' : '/validate-email';
  }

  if (
    status === registrationStatus.finalizeRegistration &&
    currPath !== '/external/registration'
  ) {
    newPath = isExternalFlow ? '/external/registration' : '/registration';
  }

  if (
    (status === registrationStatus.validateRegistration ||
      status === registrationStatus.validateId) &&
    currPath !== '/external/validate-register'
  ) {
    newPath = isExternalFlow
      ? '/external/validate-identity'
      : '/wallet/validate-identity';
  }
  if (
    status === registrationStatus.validateId &&
    currPath !== '/wallet/validate-register' &&
    currPath !== '/external/validate-register' &&
    currPath !== '/external/validate-identity'
  ) {
    newPath = isExternalFlow
      ? '/external/validate-identity'
      : '/wallet/validate-identity';
  }
  if (
    status === registrationStatus.waitingIdValidation &&
    currPath !== '/external/validate-identity'
  ) {
    newPath = isExternalFlow
      ? '/external/validate-identity'
      : '/wallet/validate-identity';
  }
  if (status === registrationStatus.blocked) {
    newPath = isExternalFlow
      ? '/external/validate-identity'
      : '/wallet/validate-identity';
  }
  if (
    status === registrationStatus.validated &&
    currPath !== '/wallet' &&
    currPath !== '/external/validate-identity'
  ) {
    newPath = isExternalFlow ? '/external/deposit' : '/wallet';
  }
  return newPath;
};

export default getRouteByUserStatus;

export const checkCpfRegex = (cpf) => cpfRegex.test(cpf);

export const validateMinimumAge = (dateString) => {
  const MINIMUM_AGE = 18;
  const DATE_LENGTH = 10;
  const FIRST_MONTH = 1;

  if (dateString && dateString.length === DATE_LENGTH) {
    const today = new Date();
    const dayOfBirth = dateString.slice(0, 2);
    const monthOfBirth = dateString.slice(3, 5);
    const yearOfBirth = dateString.slice(6, 10);

    let age = today.getFullYear() - yearOfBirth;

    const m = today.getMonth() + FIRST_MONTH - monthOfBirth;
    const d = today.getDay() - dayOfBirth;

    if (m < 0 || (today.getMonth() === monthOfBirth && d < 0)) {
      age -= 1;
    }
    return age >= MINIMUM_AGE;
  }

  return false;
};

export const convertUrlToFile = async (url, filename, mimeType) => {
  mimeType = mimeType || (url?.match(/^data:([^;]+);/) || '')[1];
  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  return new File([buf], filename, { type: mimeType });
};

export const isArrayEqual = (arr1, arr2) =>
  arr1.sort().join() === arr2.sort().join();

export const checkIfMerchantHasBonuses = (bonuses, merchantId) =>
  bonuses?.some(
    ({ merchant_ids, transaction_type }) =>
      merchant_ids === null ||
      merchant_ids?.find(
        (id) => transaction_type === 'transfer-in' && id === merchantId,
      ),
  );

export const filterMerchantHasBonuses = (bonuses, merchantId) =>
  bonuses?.filter(
    ({ merchant_ids, transaction_type }) =>
      merchant_ids === null ||
      merchant_ids?.find(
        (id) => transaction_type === 'transfer-in' && id === merchantId,
      ),
  );

export const calculateBonus = (amount, bonus) => {
  if (bonus?.type === 'percentage') {
    const percentBonus = amount * bonus?.amount;
    const finalBonus =
      percentBonus >= bonus?.max_amount_bonus
        ? bonus?.max_amount_bonus
        : percentBonus;

    return finalBonus;
  }

  if (bonus?.type === 'fixed') {
    return bonus?.amount;
  }
  return null;
};

export const getMinimumAmountRequired = (minLimit, selectedBonus) =>
  selectedBonus?.min_amount_transaction === 0
    ? 0
    : selectedBonus?.min_amount_transaction > minLimit
    ? selectedBonus?.min_amount_transaction
    : minLimit;

const getKyc1Validation = (userValidations = []) => {
  const fields = ['cpf', 'email'];

  const validations = userValidations.filter(({ validation }) =>
    fields.includes(validation),
  );
  if (validations.every(({ status }) => status === VALIDATED)) {
    return '';
  }
  return 'pending';
};

const getKyc2Validation = (userValidations = []) => {
  const ocrStatus = userValidations.find(
    ({ validation }) => validation === 'ocr',
  )?.status;
  const facematchStatus = userValidations.find(
    ({ validation }) => validation === 'facematch',
  )?.status;

  if ([ocrStatus, facematchStatus].includes(REJECTED)) {
    return 'ocr_or_facematch_rejected';
  }

  if (ocrStatus === VALIDATED && facematchStatus === VALIDATED) {
    return '';
  }
  if ([ocrStatus, facematchStatus].includes(COMPLETED)) {
    return 'pending';
  }
  return 'ocr';
};

const getKyc3Validation = (userValidations = []) => {
  const sofStatus = userValidations?.find(
    ({ validation }) => validation === 'sof',
  )?.status;

  if (sofStatus === VALIDATED) {
    return COMPLETED;
  }

  if (sofStatus === COMPLETED) {
    return 'pending_sof';
  }

  if (sofStatus === REJECTED) {
    return 'rejected_sof';
  }

  return 'sof';
};

export const getUserPendingValidations = (userValidations = []) => {
  const kycLevel1Validations = getKyc1Validation(userValidations);
  const kycLevel2Validations = getKyc2Validation(userValidations);
  const kycLevel3Validations = getKyc3Validation(userValidations);

  return (
    kycLevel1Validations || kycLevel2Validations || kycLevel3Validations || ''
  );
};

const VALIDATED_SLUGS = {
  birth_date: VALIDATED,
  cpf: VALIDATED,
  email: VALIDATED,
  facematch: VALIDATED,
  name: VALIDATED,
  ocr: VALIDATED,
  sof: VALIDATED,
};

export const getValidationPercentage = (userValidations = []) => {
  const TOTAL_VALIDATIONS = 7;
  const ONE_HUNDRED_PERCENT = 100;
  let counter = 0;

  const validations = userValidations.filter(({ validation }) =>
    Object.keys(VALIDATED_SLUGS).includes(validation),
  );

  validations.forEach(({ validation, status }) => {
    if (status === VALIDATED_SLUGS[validation]) {
      counter += 1;
    }
  });

  return Math.floor((counter / TOTAL_VALIDATIONS) * ONE_HUNDRED_PERCENT);
};

export const createRandomId = () =>
  Date.now().toString(36) + Math.random().toString(36).substring(2, 11);

export const extractErrorSlug = (response) => {
  if (response?.data?.message === 'validation-error') {
    return Object.values(response.data.data).map((slug) => slug);
  }
  return [response?.data?.message];
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const getFirstCategoryWithActivePromotion = (
  promotionList,
  categories,
) => {
  let firstCategoryWithPromotion = '';

  categories.forEach((slug) => {
    if (firstCategoryWithPromotion) {
      return;
    }

    if (promotionList.find(({ category }) => category === slug)) {
      firstCategoryWithPromotion = slug;
    }
  });
  return firstCategoryWithPromotion ?? 'vpag';
};

export const setupPoptinCampaigns = (campaignList) => {
  const campaigns = JSON.parse(localStorage.getItem('campaigns'));

  if (campaigns?.length || campaignList?.length) {
    const list = campaignList?.length ? campaignList : campaigns;
    const stringList = list.map((id) => `var poptin_campaign_id_${id}=true;`);
    let scriptString = stringList.join('\n');
    scriptString += 'var all_user_poptin=true;';
    const script = document.createElement('script');

    script.innerHTML = scriptString;
    document.body.appendChild(script);

    const poptinScript = document.createElement('script');
    poptinScript.src = POPTIN_SCRIPT_SRC;
    poptinScript.async = true;
    poptinScript.id = 'pixel-script-poptin';
    document.head.appendChild(poptinScript);
  }
};

export const checkString = (str) => {
  const chars = Array.from(str);

  const hasUppercase = chars.some((char) => /[A-Z]/.test(char));
  const hasLowercase = chars.some((char) => /[a-z]/.test(char));
  const hasNumeric = chars.some(
    (char) => !Number.isNaN(Number(char)) && char !== ' ',
  );
  const hasSpecial = chars.some((char) => /[!@#$%^&*(),.?":{}|<>]/.test(char));

  return {
    hasUppercase,
    hasLowercase,
    hasNumeric,
    hasSpecial,
  };
};
