import React from 'react';
import PropTypes from 'prop-types';

function TwText({
  text = '',
  size = '',
  font = 'arboriaRegular',
  tag: Tag,
  classList = '',
  className = '',
  color = 'white',
  inlineSize = '',
  onClick,
  children,
}) {
  const sizes = {
    xs: 'text-xs',
    sm: 'text-sm',
    base: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
  };

  const fonts = {
    arboriaLight: 'font-arboria-light',
    arboriaRegular: 'font-arboria-regular',
    arboriaBold: 'font-arboria-bold',
  };

  const TagName = Tag;

  return (
    <TagName
      className={`${sizes[size] || inlineSize} text-${color} ${
        fonts[font]
      } ${classList} ${className} text-pretty`}
      onClick={onClick}
    >
      {children || text}
    </TagName>
  );
}

TwText.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string,
  tag: PropTypes.elementType,
  font: PropTypes.string,
  classList: PropTypes.string,
  color: PropTypes.string,
  inlineSize: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

TwText.defaultProps = {
  tag: 'p',
};

export default TwText;
