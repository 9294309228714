import React from 'react';

import FaqGlossary from '@/components/organisms/FaqGlossary';
import FaqTopicList from '@/components/organisms/FaqTopicList';
import Loading from '@/components/atoms/Loading';
import MainTemplate from '@/components/templates/MainTemplate';
import i18n from '@/translate/i18n';
import useFaq from '@/hooks/useFaq';

import { Container, ContentWrapper } from './styles';

function Faq() {
  const { isFetching, data } = useFaq();

  return (
    <MainTemplate
      containerProps={{ gradientLight: true }}
      headerProps={{ title: i18n.t('faq.title'), returnTo: '/wallet' }}
    >
      <Container>
        {isFetching ? (
          <div className="size-full grid place-content-center">
            <Loading />
          </div>
        ) : (
          <ContentWrapper>
            <FaqTopicList hideInMobile data={data ?? []} />
            <FaqGlossary data={data ?? []} />
          </ContentWrapper>
        )}
      </Container>
    </MainTemplate>
  );
}

export default Faq;
