import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { PiX } from 'react-icons/pi';
import { getTransactionByUuid, getUserPoints } from '@/services/api';
import { registrationStatus } from '@/helpers/constants';
import useUpdateUser from '@/hooks/useUpdateUser';
import { useUserData } from '@/context/userContext';

import CheckRegistration from '@/components/molecules/CheckRegistration';
import TwButton from '@/components/atoms/Buttons/TwButton';
import TwText from '@/components/v2/atoms/TwText';
import TwTitle from '@/components/atoms/TwTitle';
import { ReactComponent as Logo } from '@/assets/vpag-simple-horizontal.svg';

const STATUS_PENDING = 0;
function IdentityValidationStatus({ isExternalFlow, alreadyInValidation }) {
  const history = useHistory();
  const { userData, setUserData } = useUserData();
  const [isValidated, setIsValidate] = useState(false);
  const { updateUserData } = useUpdateUser();

  const handleStart = async () => {
    const points = await getUserPoints(userData?.id).then(
      (res) => res?.data?.points,
    );
    await updateUserData({ points });
  };

  const handleCompleteValidations = async () => {
    const pendingTransferUuid = sessionStorage.getItem('pendingApiTransaction');
    try {
      const res = await getTransactionByUuid(pendingTransferUuid);

      const { transaction, merchant } = res.data;
      localStorage.setItem(
        'transaction',
        JSON.stringify({ ...transaction, merchant }),
      );
      setUserData((state) => ({
        ...state,
        status: registrationStatus.validated,
        kyc_level: 1,
      }));

      if (transaction?.type === 'transfer-in') {
        history.push('/external/deposit');
      } else {
        history.push(`/transfer-api/${pendingTransferUuid}`);
      }
    } catch (err) {
      history.push(`/transfer-api/${pendingTransferUuid}`);
    }
  };

  useEffect(() => {
    const onValidate = () => {
      if (isExternalFlow) {
        handleCompleteValidations();
      } else if (!isValidated) {
        setIsValidate(true);
      }
    };

    if (userData?.status === registrationStatus.validated) {
      onValidate();
    }
  }, [isValidated]);

  useEffect(() => {
    if (userData?.status === STATUS_PENDING) {
      const newUrl = '/new-path';
      window.history.pushState(null, '', newUrl);
    }
  }, [userData]);

  return (
    <>
      <CheckRegistration
        validated={isValidated || alreadyInValidation}
        name={userData?.name ? userData?.name?.split(' ')[0] : ''}
      />
      {isValidated && (
        <div className="fixed bg-black bg-opacity-50 top-0 left-0 size-full flex items-center justify-center">
          <div className="size-full md:size-[500px] relative bg-validationBackground bg-cover">
            <section className="absolute size-full flex items-center flex-col justify-center gap-24">
              <div className="flex flex-col items-center gap-5">
                <Logo />
                <header className="flex">
                  <div className="bg-white pl-4 pr-2 py-1 rounded-l-full">
                    <TwText text="Deu vitória? Deu vpag" color="black" />
                  </div>
                  <div className="bg-black pr-4 pl-2 py-1 rounded-r-full">
                    <TwText text="vpag.com" color="grass-800" />
                  </div>
                </header>
              </div>
              <main className="flex items-center flex-col justify-center gap-4">
                <div className="bg-grass-800 px-4 pb-1 rounded-md">
                  <TwTitle color="black" text="Parabéns!" />
                </div>
                <div>
                  <TwText text="Sua conta foi validada, disfrute da vpag!" />
                </div>
                <div className="w-56 pt-14 flex flex-col gap-2">
                  <TwButton
                    height="secondary"
                    label="Começar"
                    onClick={handleStart}
                  />
                  <span className="text-white underline cursor-pointer">
                    Conhece vpag
                  </span>
                </div>
              </main>
            </section>
            <button
              type="button"
              className="absolute right-0 p-4 cursor-pointer"
            >
              <PiX className="fill-white size-6" onClick={handleStart} />
            </button>
          </div>
        </div>
      )}
    </>
  );
}

IdentityValidationStatus.propTypes = {
  isExternalFlow: PropTypes.bool,
  alreadyInValidation: PropTypes.bool,
};

export default IdentityValidationStatus;
