import styled from 'styled-components';

import { ReactComponent as SmsIcon } from '@/assets/sms-tracking.svg';

export const Footer = styled.footer`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  min-width: 100%;
`;

export const SmsImage = styled(SmsIcon)``;
