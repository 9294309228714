import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import i18n from '@/translate/i18n';
import { formatCurrency, formatShortDate } from '@/helpers/stringFormat';

function TransactionResume({ data }) {
  const history = useHistory();

  const { bonus_amount } = data.bonus || {};
  const updatedAt =
    data?.updated_at && formatShortDate(data?.updated_at, false);
  const negative =
    data?.type === 'transfer-in' || data?.type === 'withdrawal' ? '-' : '';
  const slugMerchant =
    (data.type === 'transfer-in' || data.type === 'transfer-out') &&
    data.details?.merchant_slug
      ? data.details.merchant_slug
          .replace(/-/g, ' ')
          .replace(/^./, (str) => str.toUpperCase())
      : '';
  const transaction = `${i18n.t(`transactionHistory.${data.type}`)} ${
    data?.payment_method_slug?.toUpperCase() || ''
  } ${slugMerchant}`;

  const statusColor =
    data?.status === 'processing' || data?.status === 'pending'
      ? 'blue-secondary'
      : data?.status === 'completed'
      ? 'grass-800'
      : data?.status === 'cancelled' || data?.status === 'expired'
      ? 'cherryRed'
      : 'white';

  const { id } = data;

  return (
    <button
      type="button"
      onClick={() =>
        history.push({ pathname: `/transactions/${id}`, from: '/transactions' })
      }
      className={`list-row flex items-center justify-between text-sm ${
        statusColor === 'cherryRed' ? 'text-ashes-500' : 'text-white'
      } cursor-pointer min-h-[50px] p-[15px_5px] w-full relative border-b border-opacity-20 border-crem last:border-b-0`}
    >
      <p className="w-32 2xl:w-40 text-left">{transaction}</p>
      <p className="w-[6.5rem] text-left">{updatedAt}</p>
      <p className="w-20 text-right">
        {negative}
        {formatCurrency(
          data.customer_amount + (bonus_amount || 0),
          data.customer_currency_iso,
        )}
      </p>
      <div className="flex items-center">
        <div
          className={`mr-3 sm:mr-0 w-2 h-2 ml-2 rounded-full bg-${statusColor}`}
        />
        <span className="hidden xl:block ml-2 min-w-20 text-left">
          {i18n.t(`transactionHistory.${data.status}`)}
        </span>
      </div>
    </button>
  );
}

TransactionResume.propTypes = {
  data: PropTypes.shape({}),
}.isRequired;

export default TransactionResume;
