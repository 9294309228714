import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router-dom';

import MainTemplate from '@/components/templates/MainTemplate';
import { Title } from '@/components/molecules/WalletHeader/style';
import CarouselMech from '@/components/organisms/CarouselMech';
import BackButton from '@/components/atoms/BackButton';
import CashbackPixInfo from '@/components/molecules/Modal/Messages/CashbackPixInfo';
import OptionCashback from '@/components/atoms/OptionCashback';
import SimpleCashback from '@/assets/simpleCashback.svg';
import VpagCashback from '@/assets/vpagPlus.svg';
import { CashbackPixCreateAccount } from '@/components/molecules/Modal/Messages/CashbackPixCreateAccount';
import CashInsertUsername from '@/components/molecules/Modal/Messages/CashInsertUsername';
import { CashInsertValueTransation } from '@/components/molecules/Modal/Messages/CashInsertValueTransation';
import CashbackDetail from '@/components/molecules/Modal/Messages/CashbackDetail';
import CashbackFinished from '@/components/molecules/Modal/Messages/CashbackFinished';
import Loading from '@/components/atoms/Loading';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import i18n from '@/translate/i18n';
import {
  activeCashbackMerchant,
  getListMerchantsCashback,
  getMerchant,
  getTrackerByMerchant,
} from '@/services/api';
import CurrentBalance from '@/components/molecules/CurrentBalance';
import { TwModal } from '@/components/v2/atoms/TwModal';
import ErrorStep from '@/components/molecules/Modal/Messages/ErrorStep';

import {
  CashbackModalStep,
  FIRST_ELEMENT,
  LOCAL_STORAGE_KEY_USERNAME,
  WAIT_TIME,
} from '@/helpers/constants';
import CashbackPix from '@/components/molecules/Modal/Messages/CashbackPix';
import Modal from '@/components/molecules/Modal';

import { ActiveMechButton } from '../CashbackMerchantsActives/styles';
import TwButton from '@/components/atoms/Buttons/TwButton';
import CashbackInfoCard from '@/components/v2/Molecules/CashbackInfoCard';
import TwTitle from '@/components/atoms/TwTitle';
import ErrorActiveCashback from '@/components/organisms/ErrorActiveCashback';

function CashbackChooseMech() {
  const [isSmallHeight, setIsSmallHeight] = useState(false);
  const [merchantsAvaible, setMerchantsAvaible] = useState([]);
  const [selectedMerchant, setSelectedMerchant] = useState({});
  const [cashbackModalStep, setCashbackModalStep] = useState(
    CashbackModalStep.Initial,
  );
  const [currency, setCurrency] = useState('BRL');
  const [vpagSelected, setVpagSelected] = useState(false);
  const [errorProcess, setErrorProcess] = useState('');
  const [errorNewflowActiveCashback, setErrorNewflowActiveCashback] =
    useState('');
  const [errorLoadingMerchants, setErrorLoadingMerchants] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { isMobileLayout } = useDeviceWidth();
  const [process, setProcess] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [isloading, setIsLoading] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);

  const history = useHistory();

  const merchantName = selectedMerchant.name;

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const skipHaveUsername = () => {
    if (selectedMerchant.usernames[FIRST_ELEMENT]?.username) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY_USERNAME,
        selectedMerchant.usernames[FIRST_ELEMENT].username,
      );
      setCashbackModalStep(CashbackModalStep.InserValueTransation);
    } else {
      setCashbackModalStep(CashbackModalStep.CreateAccount);
    }
    scrollToTop();
  };

  const skipHaveUsernameBack = () => {
    if (selectedMerchant.usernames[FIRST_ELEMENT]?.username) {
      localStorage.setItem(
        LOCAL_STORAGE_KEY_USERNAME,
        selectedMerchant.usernames[FIRST_ELEMENT].username,
      );
      setCashbackModalStep(CashbackModalStep.InfoPix);
    } else {
      setCashbackModalStep(CashbackModalStep.InserUsername);
    }
  };

  const getTrackers = async (merchant) => {
    const res = await getTrackerByMerchant(merchant.id);
    return res.data;
  };

  const getMoreInfoMerchant = async (merchant) => {
    const { data } = await getMerchant(merchant.id);
    return data;
  };

  const handleActiveCashbackMerchant = async () => {
    try {
      await activeCashbackMerchant({ merchant_id: selectedMerchant.id });
      history.push(`cashback/merchants/${selectedMerchant.id}`);
    } catch {
      setErrorNewflowActiveCashback(i18n.t('cashback.errorActiveCashback'));
    }
  };

  async function updateItemsWithTrackers(data) {
    try {
      const updatedItems = await Promise.all(
        data.map(async (item) => {
          const trackers = await getTrackers(item);
          if (trackers && trackers.length > 0) {
            return { ...item, url: trackers[FIRST_ELEMENT].url };
          }
          return item;
        }),
      );
      return updatedItems;
    } catch {
      setErrorLoadingMerchants(i18n.t('cashback.errorLoadMerchants'));
      return [];
    }
  }

  const updateItemsWithMoreInfo = async (data) => {
    const newItems = await Promise.all(
      data.map(async (item) => {
        const moreInfo = await getMoreInfoMerchant(item);
        return { ...item, ...moreInfo };
      }),
    );
    return newItems;
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setVpagSelected(false);
    setErrorProcess('');
    setTimeout(
      () => setCashbackModalStep(CashbackModalStep.Initial),
      WAIT_TIME,
    );
  };

  const CurrentModalStep = {
    [CashbackModalStep.Initial]: (
      <CashbackPix
        merchantName={merchantName}
        handleCloseModal={handleCloseModal}
        handleOpenModalTutorial={() => setShowVideoTutorial(true)}
      >
        <OptionCashback
          textBody={i18n.t('cashbackOptions.simple')}
          srcIcon={SimpleCashback}
          onClick={() => {
            setVpagSelected(false);
            setCashbackModalStep(CashbackModalStep.InfoPix);
            scrollToTop();
          }}
        />
        <OptionCashback
          isVpag
          textBody={i18n.t('cashbackOptions.vpag')}
          srcIcon={VpagCashback}
          onClick={() => {
            setVpagSelected(true);
            setCashbackModalStep(CashbackModalStep.InfoPix);
            scrollToTop();
          }}
        />
      </CashbackPix>
    ),
    [CashbackModalStep.InfoPix]: (
      <CashbackPixInfo
        handleBackStep={() => setCashbackModalStep(CashbackModalStep.Initial)}
        handleNextStep={() => {
          skipHaveUsername();
          scrollToTop();
        }}
        vpagSelected={vpagSelected}
        merchantSelected={selectedMerchant}
        setVpagSelected={setVpagSelected}
      />
    ),
    [CashbackModalStep.CreateAccount]: (
      <CashbackPixCreateAccount
        handleBackStep={() => {
          setCashbackModalStep(CashbackModalStep.InfoPix);
          scrollToTop();
        }}
        merchantSelected={selectedMerchant}
        handleNextStep={() => {
          setCashbackModalStep(CashbackModalStep.InserUsername);
          scrollToTop();
        }}
      />
    ),
    [CashbackModalStep.InserUsername]: (
      <CashInsertUsername
        handleBackStep={() => {
          setCashbackModalStep(CashbackModalStep.CreateAccount);
          scrollToTop();
        }}
        merchantName={merchantName}
        merchantURLCreateAccount={selectedMerchant ? selectedMerchant.url : ''}
        handleNextStep={() => {
          setCashbackModalStep(CashbackModalStep.InserValueTransation);
          scrollToTop();
        }}
      />
    ),
    [CashbackModalStep.InserValueTransation]: (
      <CashInsertValueTransation
        merchantName={merchantName}
        merchantSelected={selectedMerchant}
        handleBackStep={skipHaveUsernameBack}
        handleNextStep={() => {
          setCashbackModalStep(CashbackModalStep.Resume);
          scrollToTop();
        }}
        setCurrency={setCurrency}
      />
    ),
    [CashbackModalStep.Resume]: (
      <CashbackDetail
        handleBackStep={() => {
          setCashbackModalStep(CashbackModalStep.InserValueTransation);
        }}
        merchantName={merchantName}
        currentMerchant={selectedMerchant}
        isVpag={vpagSelected}
        currentCurrency={currency}
        setProcess={setProcess}
        setError={setErrorProcess}
        merchantSelected={selectedMerchant}
        firstTime
      />
    ),
    [CashbackModalStep.Process]: (
      <div className="w-screen h-screen flex justify-center">
        <Loading />
      </div>
    ),
    [CashbackModalStep.Finished]: (
      <CashbackFinished
        merchantSelected={selectedMerchant}
        onClose={handleCloseModal}
      />
    ),
    [CashbackModalStep.ErrorProcess]: <ErrorStep />,
  };

  const isNewIntegration = selectedMerchant?.new_integration;

  const handleCloseModalTutorial = () => {
    setShowVideoTutorial(false);
  };

  const getMerchants = async () => {
    try {
      setIsLoading(true);
      const res = await getListMerchantsCashback();
      const dt = res.data.filter(
        (item) =>
          !item.usernames.length ||
          !item.usernames[FIRST_ELEMENT].cashback_active_by_customer,
      );
      const updatedItems = await updateItemsWithTrackers(dt);
      const itemsMoreInfo = await updateItemsWithMoreInfo(updatedItems);

      setMerchantsAvaible(itemsMoreInfo);
      setSelectedMerchant(itemsMoreInfo[FIRST_ELEMENT]);
    } catch {
      setErrorLoadingMerchants(i18n.t('cashback.errorLoadMerchants'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMerchants();
    setCashbackModalStep(CashbackModalStep.Initial);
  }, []);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }
    if (process) {
      setCashbackModalStep(CashbackModalStep.Process);
    } else if (errorProcess === '') {
      setCashbackModalStep(CashbackModalStep.Finished);
    } else {
      setCashbackModalStep(CashbackModalStep.ErrorProcess);
    }
  }, [process]);

  useEffect(() => {
    const mediaQuery = window.matchMedia(
      '(max-height: 570px) and (max-width: 480px)',
    );
    const handleMediaQueryChange = (event) => {
      setIsSmallHeight(event.matches);
    };
    mediaQuery.addEventListener('change', handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);
    return () => {
      mediaQuery.removeEventListener('change', handleMediaQueryChange);
    };
  }, []);

  return (
    <MainTemplate
      hideHeader={isMobileLayout}
      containerProps={{ gradient: true }}
      headerProps={{ mobileFullVersion: false }}
    >
      {isloading ? (
        <div className="h-full flex justify-center items-center">
          <Loading />
        </div>
      ) : errorNewflowActiveCashback ? (
        <ErrorActiveCashback
          setErrorActiveCashback={setErrorNewflowActiveCashback}
        />
      ) : (
        <>
          <div className="w-full py-5 gap-[1.56rem] md:px-10 hidden md:block">
            <div className="w-fit">
              <BackButton to="/cashback" text="Voltar" />
            </div>
          </div>
          <div className="flex flex-col gap-[1.5625rem] items-center">
            <header className="w-full flex items-center mt-10 md:mt-5  px-5 relative">
              <div className="md:w-full absolute md:relative transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                <Title fontSize={isMobileLayout ? '18px' : '32px'}>
                  {i18n.t('cashback.title')}
                </Title>
              </div>
              <div
                className={`absolute left-1/4 block md:hidden ${
                  showModal ? 'z-0' : 'z-10'
                }`}
              >
                <BackButton to="/cashback" />
              </div>
            </header>
            {isMobileLayout && <CurrentBalance />}
            {errorLoadingMerchants ? (
              <TwTitle text={i18n.t(errorLoadingMerchants)} />
            ) : (
              <p className="font-bold text-xl md:text-2xl text-white w-full md:w-auto">
                {i18n.t('cashback.chooseMerchant')}
              </p>
            )}
            <div className="-mt-6 w-[690px] flex flex-col items-center">
              {!errorLoadingMerchants && (
                <>
                  <CarouselMech
                    data={merchantsAvaible}
                    setSelectedMerchant={setSelectedMerchant}
                    handleClickItem={() => setShowModal(true)}
                  />
                  {isNewIntegration && (
                    <CashbackInfoCard
                      merchant={selectedMerchant}
                      onClickActiveButton={handleActiveCashbackMerchant}
                    />
                  )}
                  <div className="pb-10 md:pb-10">
                    {isNewIntegration ? (
                      <TwButton
                        label={i18n.t('cashback.active')}
                        classList="!w-80 block md:hidden"
                        font="arboria-bold"
                        onClick={handleActiveCashbackMerchant}
                      />
                    ) : (
                      <ActiveMechButton
                        loading={isloading}
                        onClick={() => {
                          setShowModal(true);
                        }}
                        className="!font-extrabold"
                        disabled={
                          selectedMerchant.usernames?.[FIRST_ELEMENT]
                            ?.cashback_blocked
                        }
                      >
                        {i18n.t('cashback.activeMerchant')}
                        {selectedMerchant ? selectedMerchant.name : ''}
                      </ActiveMechButton>
                    )}
                  </div>
                </>
              )}
            </div>
            {!isSmallHeight ? (
              <Modal
                centralized
                closeButtonWhite
                handleClose={handleCloseModal}
                showModal={showModal}
                height={isMobileLayout ? '100vh' : '621px'}
                width={isMobileLayout ? '100vw' : '451px'}
                hiddenCloseButton={isMobileLayout}
              >
                {CurrentModalStep[cashbackModalStep]}
              </Modal>
            ) : (
              showModal &&
              createPortal(
                <dialog
                  open={showModal}
                  className="absolute top-0 left-0 z-50 bg-shadow-800 "
                >
                  {CurrentModalStep[cashbackModalStep]}
                </dialog>,
                document.body,
              )
            )}
            <TwModal
              isOpen={showVideoTutorial}
              onClose={handleCloseModalTutorial}
              classList="md:!h-fit md:!w-fit bg-transparent "
              classListContainer="z-[100]"
              classListChildren="md:mr-10 md:mb-5"
              closeButtonWhite
            >
              <iframe
                className="w-full h-64 md:w-[697px] md:h-[400px] rounded-lg shadow-lg"
                src={
                  isMobileLayout
                    ? 'https://www.youtube.com/embed/7e7y4Ks3Kvc?si=Pz0_3RkEi8DiKrXl'
                    : 'https://www.youtube.com/embed/8U0H7kZMEnE?si=fksdUzFpy9p4Q86R'
                }
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowFullScreen
              />
            </TwModal>
          </div>
        </>
      )}
    </MainTemplate>
  );
}

export default CashbackChooseMech;
