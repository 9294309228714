import { ReactComponent as LimitAccess } from '@/assets/Kyc/user-remove.svg';
import { ReactComponent as Shield } from '@/assets/Kyc/shield-slash.svg';
import { ReactComponent as Clock } from '@/assets/Kyc/clock.svg';
import { ReactComponent as Card } from '@/assets/Kyc/card-remove.svg';
import i18n from '@/translate/i18n';

export default {
  over_18: {
    icon: LimitAccess,
    title: i18n.t('validations.kyc_errors.limit_access'),
    description: i18n.t('validations.kyc_errors.over_18'),
  },
  has_no_sanctions: {
    icon: Shield,
    title: i18n.t('validations.kyc_errors.limit_access'),
    description: i18n.t('validations.kyc_errors.has_no_sanctions'),
  },
  has_cpf: {
    icon: Clock,
    title: i18n.t('validations.kyc_errors.time_required'),
    description: i18n.t('validations.kyc_errors.pending_analysis'),
  },
  parameters_and_document_are_equal: {
    icon: Clock,
    title: i18n.t('validations.kyc_errors.time_required'),
    description: i18n.t('validations.kyc_errors.pending_analysis'),
  },
  ocr_equals_parameters: {
    icon: Clock,
    title: i18n.t('validations.kyc_errors.time_required'),
    description: i18n.t('validations.kyc_errors.pending_analysis'),
  },
  parameters_and_document_undefined_or_equal: {
    icon: Clock,
    title: i18n.t('validations.kyc_errors.time_required'),
    description: i18n.t('validations.kyc_errors.pending_analysis'),
  },
  cpf_equal_name: {
    icon: Clock,
    title: i18n.t('validations.kyc_errors.time_required'),
    description: i18n.t('validations.kyc_errors.pending_analysis'),
  },
  cpf_has_not_dead: {
    icon: Card,
    title: i18n.t('validations.kyc_errors.ops'),
    description: i18n.t('validations.kyc_errors.unable_to_open'),
  },
  cpf_error_code: {
    icon: Card,
    title: i18n.t('validations.kyc_errors.ops'),
    description: i18n.t('validations.kyc_errors.unable_to_open'),
  },
  cpf_query: {
    icon: Card,
    title: i18n.t('validations.kyc_errors.ops'),
    description: i18n.t('validations.kyc_errors.unable_to_open'),
  },
  valid_cpf_number: {
    icon: Card,
    title: i18n.t('validations.kyc_errors.ops'),
    description: i18n.t('validations.kyc_errors.unable_to_open'),
  },
};
