import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { Paragraph } from '@/components/atoms/Typography/styles';

import { Input, InputWrapper } from './styles';

const BACKSPACE_KEY_CODE = 8;
const ONLY_NUMBERS_REGEX = /[^0-9]/g;

function CustomCodeInputField({
  code,
  setCode,
  length,
  subtext,
  error = false,
  setIsError,
}) {
  const inputs = useRef([]);

  const processInput = (e, slot) => {
    const num = e.target.value;
    const newCode = [...code];
    newCode[slot] = num.replace(ONLY_NUMBERS_REGEX, '');
    setCode(newCode);
    if (slot !== length - 1 && newCode[slot]) {
      inputs.current[slot + 1].focus();
    }
  };

  const onKeyUp = (e, slot) => {
    if (e.keyCode === BACKSPACE_KEY_CODE && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot] = '';
      setCode(newCode);
      setIsError(false);
      inputs.current[slot - 1].focus();
    }
  };

  return (
    <div>
      <InputWrapper
        className="mb-2 !w-full"
        onPaste={(e) => {
          e.preventDefault();
          const paste = e.clipboardData.getData('text');
          if (!paste.match(/^[0-9]+$/)) return;
          const newCode = [...code];
          paste.split('').forEach((num, idx) => {
            if (idx < length) {
              newCode[idx] = num;
            }
          });
          setCode(newCode);

          if (newCode[length - 1]) {
            inputs.current[length - 1].focus();
          }
        }}
      >
        {code.map((num, idx) => (
          <Input
            className={`bg-transparent text-[32px] !h-10 p-[0.4rem] text-center !w-[30px] md:!w-[60px] border-b font-arboria-bold ${
              error
                ? 'border-cherryRed text-cherryRed'
                : 'border-grass-800 text-white'
            } ${!error ? 'text-white' : ''}  `}
            key={Number(idx)}
            type="text"
            inputMode="numeric"
            maxLength={1}
            value={num}
            autoFocus={!code[0].length && idx === 0}
            onChange={(e) => processInput(e, idx)}
            onFocus={({ target }) => target.select()}
            onKeyUp={(e) => onKeyUp(e, idx)}
            ref={(ref) => inputs.current.push(ref)}
          />
        ))}
      </InputWrapper>
      {error && (
        <Paragraph className="!text-xs !text-start !text-cherryRed">
          *Código inválido
        </Paragraph>
      )}
      {subtext && <Paragraph>{subtext}</Paragraph>}
    </div>
  );
}

CustomCodeInputField.propTypes = {
  code: PropTypes.arrayOf(PropTypes.number).isRequired,
  length: PropTypes.string.isRequired,
  setCode: PropTypes.func.isRequired,
  subtext: PropTypes.string,
  error: PropTypes.bool,
  setIsError: PropTypes.func,
};

export default CustomCodeInputField;
