import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-self: center;
    margin-bottom: 10px;
    width: 26vw;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 20vw;
  }
`;
