/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

import TwText from '../../atoms/TwText';
import ToggleBetweenOptions from '../../atoms/ToggleBetweenOptions';
import TwButton from '@/components/atoms/Buttons/TwButton';
import i18n from '@/translate/i18n';
import { optionsTypesCashback, TYPES_CASHBACK } from '@/helpers/constants';

const ONE_HUNDRED = 100;

const convertPercent = (value) => value * ONE_HUNDRED;

const informationsOptionsCashback = (
  merchantName = '',
  simpleCashback = { percent: 0, limit: 0 },
  vplusCashback = { percent: 0, limit: 0 },
) => ({
  [TYPES_CASHBACK.SIMPLE]: {
    title: 'Cashback Simple',
    discount: i18n.t('cashbackDetails.discount', {
      percent: convertPercent(simpleCashback.percent),
      limit: simpleCashback.limit,
    }),
    validText: i18n.t('cashbackDetails.validationTextVplusCashback'),
    textTip: i18n.t('cashbackDetails.notNecessary'),
  },
  [TYPES_CASHBACK.VPLUS]: {
    title: 'Cashback V+',
    discount: i18n.t('cashbackDetails.discount', {
      percent: convertPercent(vplusCashback.percent),
      limit: vplusCashback.limit,
    }),
    validText: i18n.t('cashbackDetails.validationTextVplusCashback'),
    textTip: (
      <Trans
        i18n={i18n}
        i18nKey="cashbackDetails.textTipCashbackVplusCashback"
        values={{ merchantName }}
        components={[
          <Link to="/club-vplus" className="md:text-grass-700 underline" />,
          <span className="md:font-arboria-bold" />,
        ]}
      />
    ),
  },
});

const CashbackInfoCard = ({ merchant, onClickActiveButton }) => {
  const [selectedOption, setSelectedOption] = useState(TYPES_CASHBACK.SIMPLE);
  const { name: merchantName, cashbacks = [] } = merchant;

  const { simpleCashback, vplusCashback } = cashbacks?.reduce(
    (acc, cashback) => {
      if (cashback.user_type === 'general') {
        acc.simpleCashback = cashback;
      } else {
        acc.vplusCashback = cashback;
      }
      return acc;
    },
    {
      simpleCashback: undefined,
      vplusCashback: undefined,
    },
  );

  const informations = informationsOptionsCashback(
    merchantName,
    simpleCashback,
    vplusCashback,
  );

  return (
    <div className="border border-gray-500 rounded-md min-h-[214px] w-80 px-4 py-5 mb-6 md:w-[583px] md:px-[34px] md:py-8">
      <ToggleBetweenOptions
        options={optionsTypesCashback}
        onToggle={setSelectedOption}
      />
      <div className="grid grid-cols-1 md:grid-cols-2fr-1fr mt-6 mb-2 md:items-center">
        <div className="flex flex-col items-start">
          <>
            <TwText
              text={informations[selectedOption].title}
              size="lg"
              font="arboriaBold"
              classList="mb-1 md:hidden"
            />
            <TwText
              text="Detalhes"
              font="arboriaBold"
              classList="mb-1 hidden md:block !text-[22px]"
            />
            <TwText
              text={informations[selectedOption].discount}
              classList="leading-normal md:text-lg"
            />
            <TwText
              classList="leading-normal text-left text-xs md:text-lg"
              text={informations[selectedOption].validText}
            />
          </>
        </div>
        <TwButton
          label={i18n.t('cashback.active')}
          classList="hidden md:block max-w-[172px] max-h-[60px] md:text-xl"
          font="arboria-bold"
          onClick={onClickActiveButton}
        />
      </div>
      <div className="border-0 border-t border-gray-500 w-full" />
      <TwText
        size="xs"
        classList="text-left mt-2 text-xs md:text-base"
        text={informations[selectedOption].textTip}
      />
    </div>
  );
};

export default CashbackInfoCard;
