import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from '@/components/atoms/Buttons/Button/styles';
import Banner from '@/assets/bannerFinishedStep.svg';
import i18n from '@/translate/i18n';
import InputCopy from '@/components/atoms/Inputs/InputCopy';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import { ContainerCashbackPix } from '../CashbackPix/style';
import TitleModal from '@/components/molecules/TitleModal';
import { STATUS_AFFILIATE } from '@/helpers/constants';

import {
  CashbackFinishedBody,
  CashbackFinishedImage,
  ParagraphTwo,
  SpanStyled,
  SubtitleStyled,
} from './style';

const ZERO = 0;

function CashbackFinished({ merchantSelected, onClose }) {
  const { isMobileLayout } = useDeviceWidth();
  const history = useHistory();
  const handleGoCashbackActives = () => {
    history.push('/cashback/merchants-actives');
  };
  const isAffiliated =
    merchantSelected.usernames[ZERO]?.affiliate_status === STATUS_AFFILIATE;
  return (
    <ContainerCashbackPix
      isMobileLayout={isMobileLayout}
      className="!gap-0 md:!gap-0"
    >
      <div className="block md:hidden w-full">
        <TitleModal handleBackStep={onClose} />
      </div>
      <CashbackFinishedImage
        src={Banner}
        className="md:h-[300px] md:rounded-t-md"
        alt="banner vpag"
      />
      <CashbackFinishedBody
        isMobileLayout={isMobileLayout}
        className="md:mx-[40px] mx-4"
      >
        <p className="text-white !text-xl ">
          <Trans
            i18n={i18n}
            i18nKey="cashbackFinished.title"
            values={{
              typeCashback: isAffiliated
                ? `${i18n.t('cashbackFinished.typeUserVplus')}`
                : `${i18n.t('cashbackFinished.typeUserSimple')}`,
            }}
            components={[
              <span className="font-arboria-bold text-white !text-xl " />,
            ]}
          />
        </p>
        <ParagraphTwo className=" !text-xl text-justify ">
          <Trans
            i18n={i18n}
            i18nKey="cashbackFinished.info"
            components={[<SpanStyled className="!text-xl" />]}
            values={{ merchant: merchantSelected.name }}
          />
        </ParagraphTwo>
        <InputCopy value={merchantSelected.pix_key} />
        <SubtitleStyled className="text-[16px] md:text-xs">
          {i18n.t('cashbackFinished.tip', { merchant: merchantSelected.name })}
        </SubtitleStyled>
        <Button onClick={handleGoCashbackActives}>Ver cashback</Button>
      </CashbackFinishedBody>
    </ContainerCashbackPix>
  );
}
CashbackFinished.propTypes = {
  merchantSelected: PropTypes.object,
  onClose: PropTypes.func,
}.isRequired;
export default CashbackFinished;
