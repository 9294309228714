import styled from 'styled-components';

import { Form } from 'formik';

export const FormStyled = styled(Form)`
  align-items: center;
  color: ${({ theme }) => theme.colors.info.light};
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: ${({ theme }) => theme.font.size.small};
  height: 100%;
  justify-content: space-around;
  max-width: 280px;

  p {
    text-align: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    font-size: ${({ theme }) => theme.font.size.xSmall};
  }
`;

export const InputWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;
