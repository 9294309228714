import React, { createContext, useState } from 'react';

const defaultState = {
  run: false,
  tourActive: false,
  stepIndex: 0,
  steps: [],
};

export const TourContext = createContext({
  state: defaultState,
  updateTour: () => undefined,
});

const TourProvider = ({ children }) => {
  const [stateTour, setStateTour] = useState(defaultState);

  const updateTour = (newDeposit) => {
    setStateTour((oldState) => ({ ...oldState, ...newDeposit }));
  };

  const setupTour = (newDeposit) => {
    setStateTour(newDeposit);
  };

  const nextStepTour = () => {
    setStateTour((oldState) => ({
      ...oldState,
      stepIndex: oldState.stepIndex + 1,
    }));
  };

  const closeTour = () => {
    setStateTour((oldState) => ({
      ...oldState,
      run: false,
    }));
  };

  const finishTour = () => {
    setStateTour(defaultState);
  };

  return (
    <TourContext.Provider
      value={{
        stateTour,
        updateTour,
        setupTour,
        closeTour,
        finishTour,
        nextStepTour,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};

export { TourProvider };
