import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CircularLoader from '@/components/atoms/CircularLoader';

import { ocrValidation } from '@/services/api';
import i18n from '@/translate/i18n';

import { ContentWrapper, SdkIframe } from './styles';

function IFrameOcr({ ocrProviderSlug, setError }) {
  const [iframeUrl, setIframeUrl] = useState('');
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const getOcrProviderUrl = async () => {
      try {
        const res = await ocrValidation();

        setIframeUrl(res.data.url);
      } catch (err) {
        const message = err?.response?.data?.message;
        setError(i18n.t([`error.${message}`, 'error.unspecific']));
      } finally {
        setIsFetching(false);
      }
    };

    if (ocrProviderSlug) {
      getOcrProviderUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ocrProviderSlug]);

  return isFetching ? (
    <CircularLoader />
  ) : (
    <ContentWrapper>
      <SdkIframe
        title={`${ocrProviderSlug} OCR`}
        src={iframeUrl}
        allow="camera;fullscreen;accelerometer;gyroscope;magnetometer;"
      />
    </ContentWrapper>
  );
}
IFrameOcr.propTypes = {
  ocrProviderSlug: PropTypes.string,
  setError: PropTypes.func,
}.isRequired;

export default IFrameOcr;
