/**
 * @param {number} value - required
 * @param {string} selectedCurrency - optional
 * @param {bool} showDecimals - optional
 */
export const formatCurrency = (value, selectedCurrency, hideDecimals) => {
  const result = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: selectedCurrency || 'BRL',
    maximumFractionDigits: hideDecimals ? 0 : 2,
  }).format(value);

  // replace non-breaking space (&nbsp;) with a regular space
  return result.replace(/\u00a0/g, ' ');
};

/**
 * [replace dashes with slashes]
 * @param {string} date - required
 */
export const formatDate = (date) => {
  const d = date.split('-');

  return `${d[2]}/${d[1]}/${d[0]}`;
};

/**
 * [replace slashes with dashes]
 * @param {string} date - required
 */
export const formatDateReverse = (date) => {
  const d = date.split('/');

  return `${d[2]}-${d[1]}-${d[0]}`;
};

export const formatFullDate = (date) => {
  const d = new Date(date);
  return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
};
/**
 * [format the CPF number as 999.999.999-99]
 * @param {string} cpf - required
 */
export const formatCpf = (value) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1');

/**
 * [returns only numbers, without dots and dashes]
 * @param {string} cpf - required
 */

export const cpfOnlyNumbers = (cpf) => cpf.replace(/[.]/g, '').replace('-', '');

export const formatOnlyNumber = (str) => str.replace(/[^0-9]+/g, '');

/**
 * [format the CEP number as 99999-99]
 * @param {string} cpf - required
 */
export const formatCep = (value) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1');

/**
 * @param {string} number - required
 */
export const hideAccountNumber = (number) => `****${number.slice(4)}`;

/**
 * @param {string} - required
 */
export const capitalizeText = (string = '') => {
  const words = string.toLowerCase().split(' ');

  return words
    .filter((str) => !!str.length)
    .map((word) => word[0].toUpperCase() + word.substring(1))
    .join(' ');
};

/**
 * [get hours from a date string]
 * @param {string} date - required
 */
export const formatHours = (date) => {
  const d = new Date(date);
  let hours = d.getHours();
  let minutes = d.getMinutes();

  hours = hours.length < 2 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  return `${hours}:${minutes}`;
};

/**
 * [format short date as dd MMM yyy]
 * @param {string} date  - required
 * @param {bool} dateTime - optional
 */
export const formatShortDate = (date, dateTime) => {
  const dateFormated = new Date(date)
    .toLocaleString('pt-BR', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      timeZone: 'UTC',
    })
    .replace(/ de /g, ' ')
    .replace('.', '');

  if (dateTime) {
    return capitalizeText(`${dateFormated}, ${formatHours(date)}`);
  }
  return capitalizeText(dateFormated);
};

/**
 * @param {string} locale  - required
 * @param {string} currency - required
 */
export const getCurrencySymbol = (locale, currency) =>
  (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();

/**
 * [format string as dd/mm/aaaa]
 * @param {string} value - required
 */
export const parseDateFormat = (value) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/([/]\d{4})\d+?$/, '$1');

export const getShortName = (fullName = '') =>
  fullName.split(' ').slice(0, 2).join(' ');

/**
 * @param {number} value - required
 * @param {string} selectedCurrency - optional
 */
export const formatCurrencyWithoutSymbol = (value, selectedCurrency) => {
  const result = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: selectedCurrency || 'BRL',
    maximumFractionDigits: 2,
    currencyDisplay: 'code',
  }).format(value);

  return result.replace(/[a-z]{3}/i, '').trim();
};

export const hideEmailPartial = (email) => {
  if (!email) {
    return '';
  }
  return email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, '$1***@$2');
};

export const formatAddress = (address = '', type) => {
  const lastCommaIndex = address?.lastIndexOf(',');
  const addressStreet = address?.substring(0, lastCommaIndex)?.trim();
  const addressNumber = address?.substring(lastCommaIndex + 1)?.trim();

  return type === 'number' ? addressNumber : addressStreet;
};

export const formatPhoneMask = (value) =>
  value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '($1) $2')
    .replace(/(\d{5})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1');
