import React from 'react';

import { useHistory } from 'react-router-dom';
import i18n from '@/translate/i18n';

import LastTransactionsHome from '@/components/organisms/LastTransactionsHome';
import { useUserData } from '@/context/userContext';

import { TextWrapper, CardIcon } from './styles';
import { capitalizeText } from '@/helpers/stringFormat';
import TwTitle from '@/components/atoms/TwTitle';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import useTour from '@/hooks/useTour';

const CONTENT = [
  {
    id: 'home-btn-deposit',
    key: 'getStart.depositTitle',
    icon: '/icons/deposit.svg',
    elementClass: 'deposit',
    path: '/deposit',
  },
  {
    id: 'home-btn-transfer',
    key: 'getStart.transferTitle',
    icon: '/icons/transfer.svg',
    path: '/transfer',
  },
  {
    id: 'home-btn-bonus-and-promotions',
    key: 'getStart.bonusTitle',
    icon: '/icons/bonus.svg',
    path: '/bonuses-and-promotions',
  },
];

function GetStart() {
  const history = useHistory();
  const { closeTour } = useTour();
  const { userData } = useUserData();
  const { isMobileLayout } = useDeviceWidth();
  const firstNameUser = userData?.name?.split(' ')[0];

  const handleGoToPath = (path) => {
    const isDepositPath = path === '/deposit';
    if (isDepositPath) {
      closeTour();
    }
    history.push(path);
  };

  return (
    <div className="flex flex-col items-center justify-staret md:justify-center size-full px-5 lg:px-10 2xl:px-40 text-ashes-500 animate-right">
      <TwTitle
        size={isMobileLayout ? 'm_lg' : 'd_lg'}
        font="arboriaBold"
        classList="text-center mb-5 2xl:mb-10"
      >
        {i18n.t('getStart.hello')}{' '}
        <span className="text-grass-800 inline">
          {firstNameUser && capitalizeText(firstNameUser)}
        </span>
      </TwTitle>

      <div className="flex justify-between px-5 sm:px-0 items-start w-full my-5 2xl:my-10">
        {CONTENT.map(({ id, key, icon, path, elementClass }) => (
          <CardIcon
            key={id}
            onClick={() => handleGoToPath(path)}
            id={id}
            className={elementClass}
          >
            <img src={icon} alt={i18n.t(key)} />
            <TextWrapper>
              <h2>{i18n.t(key)}</h2>
            </TextWrapper>
          </CardIcon>
        ))}
      </div>
      <LastTransactionsHome />
    </div>
  );
}

export default GetStart;
