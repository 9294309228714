import styled, { css } from 'styled-components';
import SubmitButton from '@/components/atoms/Buttons/SubmitButton';

export const InsertUsernameBody = styled.div`
  ${({ isMobileLayout }) => css`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 30px;
    height: 100%;
    width: 100%;
  `}
`;
export const InsertUsernameContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
`;
export const SubmitButtonStyled = styled(SubmitButton)`
  margin: 0 auto;
  width: 15rem;
`;
