import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
import { useHistory, useLocation } from 'react-router-dom';
import LoadingFullScreen from '@/components/molecules/LoadingFullScreen';
import ConversionDetails from '@/components/organisms/ConversionDetails';
import DepositDetails from '@/components/organisms/DepositDetails';
import MainTemplate from '@/components/templates/MainTemplate';
import TransferDetails from '@/components/organisms/TransferDetails';
import WithdrawalDetails from '@/components/organisms/WithdrawalDetails';
import CustomTooltip from '@/components/v2/Molecules/CustomTooltip';
import i18n from '@/translate/i18n';
import { getTransaction } from '@/services/api';

import { ErrorText } from './styles';
import useTour from '@/hooks/useTour';
import { SIX_STEP_TOUR } from '@/helpers/constants';

const COLOR_COMMUM_TOOLTIP = '#32373a';
const DELAY_TIME_COPY_BUTTON = 800;

function TransactionStatus({ match }) {
  const { transactionId } = match.params;
  const [data, setData] = useState({});
  const [requestError, setRequestError] = useState(false);
  const [errorMessage, setErroMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [from, setFrom] = useState('');
  const location = useLocation();
  const history = useHistory();
  const { updateTour, stateTour, finishTour } = useTour();

  const getDepositInfo = useCallback(
    async (signal) => {
      try {
        setIsLoading(true);
        const res = await getTransaction(transactionId, signal);
        setData(res.data);
      } catch (err) {
        const message = err?.response?.data?.message;
        setRequestError(true);
        setErroMessage(
          i18n.t([
            `error.transactionStatus.${message}`,
            'error.transactionStatus.default',
          ]),
        );
      } finally {
        setIsLoading(false);
      }
    },
    [transactionId],
  );

  useEffect(() => {
    const controller = new AbortController();
    getDepositInfo(controller.signal);
    return () => controller.abort();
  }, [getDepositInfo, transactionId]);

  useEffect(() => {
    if (!stateTour.tourActive) return () => {};

    const handle = requestAnimationFrame(() => {
      const timeoutId = setTimeout(() => {
        updateTour({
          run: true,
          tourActive: true,
          stepIndex: SIX_STEP_TOUR,
        });
      }, DELAY_TIME_COPY_BUTTON);
      return () => clearTimeout(timeoutId);
    });
    return () => {
      cancelAnimationFrame(handle);
    };
  }, []);

  useEffect(() => {
    if (location?.from) {
      setFrom(location.from);
    }
  }, []);

  return (
    <MainTemplate
      headerProps={{
        title: i18n.t([`transactions.detailsTitle.${data?.type}`, '']),
        returnTo: from || '/wallet',
        returnAction: from
          ? () => {
              history.push(from);
            }
          : null,
      }}
    >
      {isLoading && (
        <>
          <div className="flex-1 h-full" />
          <LoadingFullScreen />
        </>
      )}
      <>
        {data.type === 'deposit' && (
          <>
            <Joyride
              steps={stateTour.steps}
              stepIndex={stateTour.stepIndex}
              tooltipComponent={CustomTooltip}
              callback={(itemData) => {
                const { action, lifecycle } = itemData;
                const isActionClose = action === 'close';
                const isLifecycleComplete = lifecycle === 'complete';

                if (isActionClose && isLifecycleComplete) {
                  finishTour();
                }
              }}
              styles={{
                options: {
                  arrowColor: COLOR_COMMUM_TOOLTIP,
                  backgroundColor: COLOR_COMMUM_TOOLTIP,
                },
              }}
              run={stateTour.run}
              spotlightClicks
            />
            <DepositDetails
              data={data}
              reloadAction={getDepositInfo}
              setData={setData}
            />
          </>
        )}
        {data.type === 'withdrawal' && (
          <WithdrawalDetails data={data} reloadAction={getDepositInfo} />
        )}
        {(data.type === 'transfer-in' || data.type === 'transfer-out') && (
          <TransferDetails data={data} />
        )}
        {data.type === 'conversion' && <ConversionDetails data={data} />}
      </>
      {requestError && <ErrorText>{errorMessage}</ErrorText>}
    </MainTemplate>
  );
}

TransactionStatus.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      transactionId: PropTypes.string,
    }),
  }),
}.isRequired;

export default TransactionStatus;
