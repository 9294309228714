import React from 'react';
import iconData from './data';
import TwTitle from '@/components/atoms/TwTitle';
import TwText from '@/components/v2/atoms/TwText';

function KycError({ errorSlug = 'cpf_error_code' }) {
  const data = iconData[errorSlug];
  const Icon = data?.icon;
  return (
    <div className="flex flex-col justify-center items-center gap-8 max-w-[300px] md:max-w-[450px]">
      <Icon className="size-20" />
      <div className="space-y-2">
        <TwTitle text={data.title} font="arboriaLight" />
        <TwText text={data.description} size="lg" />
      </div>
    </div>
  );
}

export default KycError;
