import React from 'react';

function LinkButton({ label, children, className, ...rest }) {
  return (
    <span {...rest} className={`text-grass-800 ${className || ''}`}>
      {children || label}
    </span>
  );
}

export default LinkButton;
