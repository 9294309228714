import styled, { css } from 'styled-components';

import { ReactComponent as ArrowIcon } from '@/assets/simpleArrow.svg';

import { ReactComponent as Bonus } from '@/assets/giftIcon.svg';

import { hexToRGBA } from '@/styles/colors';

const MAX_ITEMS_PER_ROW = 3;

const hideInDesktop = css`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    display: none;
  }
`;

export const Merchant = styled.div`
  ${({ theme, listLength }) => css`
    display: flex;
    flex-flow: row wrap;
    width: 85%;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      display: grid;
      grid-template-columns: ${listLength <= MAX_ITEMS_PER_ROW
        ? `repeat(${listLength}, 1fr)`
        : `repeat(3, 1fr)`};
      justify-content: center;
      justify-items: center;
      max-width: 76%;
    }
  `};
`;

export const MerchantsWrapper = styled.div`
  align-items: center;
  animation: goLeftDefault 0.6s;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    animation: none;
    height: auto;
    max-width: 515px;
    overflow-y: auto;
    text-align: center;
  }
`;

export const LogoWrapper = styled.div`
  ${({ theme, selected }) => css`
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid ${theme.colors.info.gray};
    color: ${selected ? theme.colors.primary : theme.colors.info.light};
    display: flex;
    font: ${`${theme.font.size.base} ${
      theme.font.family[selected ? 'secondary' : 'primary']
    }`};
    height: 79px;
    text-transform: capitalize;
    width: 100%;

    p {
      line-height: 0.8;
      margin-left: 25px;
    }

    &:hover {
      p {
        color: ${theme.colors.primary};
      }
    }

    @media screen and (min-width: ${theme.breakpoints.sm}) {
      align-items: center;
      border: none;
      flex-direction: column;
      height: auto;
      margin: 0.5rem;
      position: relative;
      width: 100px;

      p {
        margin: 0 0 9px;
      }
    }
  `}
`;

export const ArrowButton = styled.div`
  ${hideInDesktop}
  align-items: center;
  background-color: ${({ theme }) => theme.colors.info.gray};
  border-radius: 37px;
  display: flex;
  height: 27px;
  justify-content: center;
  margin-left: 40px;
  width: 27px;
`;

export const Arrow = styled(ArrowIcon)`
  fill: ${({ theme }) => theme.colors.light};
`;

export const ReverseWrapper = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    margin: 1rem 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column-reverse;

    h1 {
      margin: 0 0 1rem 0;
    }
  }
`;

export const ContentWrapper = styled.section`
  ${({ theme, isOpen }) => css`
    align-items: center;
    column-gap: 2%;
    display: grid;
    grid-template-columns: ${isOpen ? '47% 1% 47%' : '1fr'};
    height: 100%;
    justify-items: center;
    width: 100%;

    @media screen and (min-width: ${theme.breakpoints.sm}) {
    }
  `}
`;

export const Section = styled.section`
  align-items: center;
  animation: goRightDefault 0.5s forwards ease;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  width: 100%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    animation: none;
    gap: 5vh;
    height: 80%;
    justify-content: space-between;
    width: auto;
  }
`;

export const SectionDivider = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    animation: opacityAnimationDefault 0.8s ease;
    border-left: 1px solid
      ${({ theme }) => hexToRGBA(theme.colors.info.primary, 0.2)};
    min-height: 90%;
    display: block;
  }
`;

export const BonusIcon = styled(Bonus)`
  height: 15px;
  margin-right: 5px;
  width: 15px;

  rect {
    fill: ${({ theme }) => theme.colors.secondary};
  }
`;

export const Spacer = styled.span`
  height: 22px;
`;
