import React, { useEffect, useState } from 'react';

import { TwModal } from '../../atoms/TwModal';
import StartDepositTour from '../../Molecules/StartDepositTour';
import useTour from '@/hooks/useTour';
import { useUserData } from '@/context/userContext';

const MAX_LOGINS_TO_SHOW_TOUR = 3;

const ModalStartDepositTour = () => {
  const { updateTour } = useTour();
  const { userData } = useUserData();
  const [openModalStartingTourDeposit, setOpenModalStartingTourDeposit] =
    useState(false);

  const { first_deposit_id: firstDeposit, first_three_login: firstThreeLogin } =
    userData;
  const mayShowTour = firstThreeLogin < MAX_LOGINS_TO_SHOW_TOUR;

  const handleCloseModalTourDeposit = () => {
    setOpenModalStartingTourDeposit(false);
    updateTour({ tourActive: false });
  };

  const handleContinueButton = () => {
    setOpenModalStartingTourDeposit(false);
    updateTour({ run: true, tourActive: true });
  };

  useEffect(() => {
    if (mayShowTour && !firstDeposit) {
      setOpenModalStartingTourDeposit(true);
    }
  }, []);

  return (
    <TwModal
      isOpen={openModalStartingTourDeposit}
      closeButtonWhiteOutlined
      onClose={handleCloseModalTourDeposit}
      classList="w-[351px] h-[616px] lg:max-w-[950px] lg:!h-[533px] bg-validationBackground bg-cover bg-center rounded-3xl"
    >
      <StartDepositTour
        onClickContinueButton={handleContinueButton}
        onClickSkipButton={handleCloseModalTourDeposit}
      />
    </TwModal>
  );
};

export default ModalStartDepositTour;
