import React from 'react';
import TwText from '@/components/v2/atoms/TwText';
import i18n from '@/translate/i18n';

function CustomTooltip({ closeProps, step, index, size }) {
  const isDisplayProgressTour = step.showProgress;

  return (
    <div className="bg-gray-800 text-sm text-white rounded-2xl pt-2 w-[292px] min-h-[117px] lg:max-w-[336px]">
      <div className="w-full h-fit flex justify-end px-4 items-end">
        {isDisplayProgressTour && (
          <div className="mr-auto">
            <TwText
              size="xs"
              classList="text-[#8A8A8A] text-xs lg:!text-base"
              text={i18n.t('actions.progressStep', {
                step: index + 1,
                total: size,
              })}
            />
          </div>
        )}
        <button
          type="button"
          className="text-2xl text-[#8A8A8A]"
          {...closeProps}
        >
          &times;
        </button>
      </div>
      <div className="p-4 pt-0 mt-1 grid gap-1 lg:gap-4">
        {step.title && (
          <TwText
            classList="font-semibold !text-sm lg:!text-[22px]"
            font="arboriaBold"
          >
            {step.title}
          </TwText>
        )}
        <TwText classList="leading-[18px] border-red-100 !text-sm lg:!text-[18px] w-full whitespace-nowrap">
          {step.content}
        </TwText>
      </div>
    </div>
  );
}

export default CustomTooltip;
