import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { toast } from 'react-hot-toast';

import { TextButton } from '@/components/atoms/Buttons/TextButton/styles';
import { Paragraph } from '@/components/atoms/Typography/styles';
import RegisterTemplate from '@/components/templates/RegisterTemplate';
import { AuthContext } from '@/context/authContext';
import { useUserData } from '@/context/userContext';
import { extractErrorSlug } from '@/helpers/functions';
import { hideEmailPartial } from '@/helpers/stringFormat';
import { resendEmail, validateEmailWithCode } from '@/services/api';
import i18n from '@/translate/i18n';
import { ReactComponent as SucessIcon } from '@/assets/tick-square.svg';
import { ReactComponent as ErrorIcon } from '@/assets/error-square.svg';

import { Footer, SmsImage } from './styles';
import TransferApiTemplate from '@/components/templates/TransferApiTemplate';
import { RightWrapper } from '../SignIn/styles';
import LoginRegisterSelector from '@/components/molecules/LoginRegisterSelector';
import CustomCodeInputField from '@/components/atoms/Inputs/CustomCodeInputField';
import TwButton from '@/components/atoms/Buttons/TwButton';
import TwTitle from '@/components/atoms/TwTitle';

const CODE_LENGTH = 6;
const TIME_TO_REDIRECT = 3000;
const EMPTY_CODE = Array(CODE_LENGTH).fill('');

function ValidateEmail() {
  const history = useHistory();
  const [isValidated, setIsValidated] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const { setIsAuthenticated } = useContext(AuthContext);
  const { userData, setUserData } = useUserData();
  const [validationError, setValidationError] = useState(false);
  const [code, setCode] = useState(EMPTY_CODE);
  const [codeFieldFull, setCodeFieldFull] = useState(false);
  const { pathname } = history.location;
  const isExternalFlow = pathname?.includes('/external');
  const codeFormatted = code.join('').trim('');
  const PageTemplate = isExternalFlow ? TransferApiTemplate : RegisterTemplate;
  const receivedCode = new URLSearchParams(window.location.search).get(
    'email_validation_code',
  );

  const resendValidateEmail = async () => {
    const request = resendEmail({ email: userData?.email });

    toast.promise(request, {
      loading: i18n.t('validateEmail.sending'),
      success: () => {
        setValidationError(false);
        return i18n.t('validateEmail.resendEmailSucess');
      },
      error: (err) => {
        const messages = extractErrorSlug(err?.response);
        return i18n.t([
          `error.resendEmail.${messages?.[0]}`,
          'error.resendEmail.unspecific',
        ]);
      },
    });
  };
  const validateEmail = async () => {
    setIsError(false);
    try {
      const res = await validateEmailWithCode({
        code: receivedCode || codeFormatted,
      });
      setValidationError(false);
      setUserData(res.data);
      setIsValidated(true);
      setTimeout(() => {
        history.push(
          isExternalFlow ? '/external/registration' : '/registration',
        );
      }, TIME_TO_REDIRECT);
    } catch (err) {
      if (err?.response?.data?.data?.code[0] === 'The code is invalid.') {
        setIsError(true);
      } else {
        setIsExpired(true);
      }
    }
  };

  useLayoutEffect(() => {
    if (userData.status) {
      history.push('/wallet');
    }
  }, []);

  useEffect(() => {
    if (receivedCode) {
      validateEmail();
    } else {
      setCodeFieldFull(!code.includes(''));

      if (code.every((item) => item)) {
        validateEmail();
      }
    }
  }, [code]);

  return (
    <PageTemplate>
      <RightWrapper>
        {isValidated || isExpired ? (
          <div
            className={`flex flex-col items-center justify-between gap-20 ${
              isExternalFlow ? 'h-[100%]' : 'h-[90%]'
            } w-full`}
          >
            {!isValidated && (
              <LoginRegisterSelector
                isExternalLogin={isExternalFlow}
                origin="validateEmail"
                stateProgressBar="codeValidateFull"
              />
            )}

            <div className="flex flex-col justify-center items-center h-full gap-5">
              {isValidated ? <SucessIcon /> : <ErrorIcon />}
              <TwTitle size="xl" color="white" classList="text-center">
                <Trans
                  i18n={i18n}
                  i18nKey={`${
                    isValidated
                      ? 'validateEmail.sucessMessage'
                      : 'validateEmail.expiredMessage'
                  }`}
                  components={[<span className="text-grass-800" />]}
                />
              </TwTitle>
            </div>
            {isExpired && (
              <div className="w-full h-fit max-w-[600px] px-10 md:px-16">
                <TwButton
                  label="Enviar novamente"
                  height="secondary"
                  onClick={() => {
                    setIsExpired(false);
                    setCode(EMPTY_CODE);
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <>
            <LoginRegisterSelector
              isExternalLogin={isExternalFlow}
              origin="validateEmail"
              isCreateAccount
              stateProgressBar={
                codeFieldFull
                  ? 'codeValidateFull'
                  : 'createAccountButtonIsValid'
              }
            />
            <div className="size-full flex flex-col justify-between px-10 md:px-16 gap-5 max-w-[600px] bg-on mt-[4vh] pb-10">
              <div className="flex flex-col items-center gap-6">
                <TwTitle size="xl" color="white" classList="text-center">
                  <Trans
                    i18nKey={
                      validationError
                        ? 'validateEmail.titleError'
                        : 'validateEmail.title'
                    }
                    i18n={i18n}
                    components={[<span className="text-grass-800" />]}
                  />
                </TwTitle>
                <SmsImage />
                <div className="flex flex-col items-center gap-6">
                  <Paragraph
                    family="primary"
                    className="!text-xl"
                    whiteSpace="initial"
                  >
                    <Trans
                      i18n={i18n}
                      i18nKey="validateEmail.instructions"
                      tOptions={{ email: hideEmailPartial(userData?.email) }}
                      components={[<span />]}
                    />
                  </Paragraph>
                  <Paragraph
                    family="primary"
                    size="small"
                    whiteSpace="initial"
                    className="!text-[16px]"
                  >
                    {i18n.t('validateEmail.writteCode')}
                  </Paragraph>
                  <CustomCodeInputField
                    length={CODE_LENGTH}
                    code={code}
                    setCode={setCode}
                    error={isError}
                    setIsError={setIsError}
                  />
                </div>
              </div>

              <Footer>
                {!isError && (
                  <div data-testid="validate-email-resend-email-text">
                    <Paragraph
                      family="primary"
                      size="small"
                      className="!text-[18px]"
                    >
                      <Trans
                        i18nKey="validateEmail.emailNotReceived"
                        i18n={i18n}
                        components={[
                          <span
                            tabIndex={0}
                            role="button"
                            onKeyDown={resendValidateEmail}
                            onClick={resendValidateEmail}
                            id="validate-email-resend-email-btn"
                            display="inline"
                            className="pointer !text-[18px]"
                          />,
                        ]}
                      />
                    </Paragraph>
                  </div>
                )}

                <TextButton
                  id="validate-email-back-btn"
                  type="button"
                  color="primary"
                  margin="0.5rem 0 0 0"
                  family="primary"
                  className="!text-[18px]"
                  onClick={() => {
                    setIsAuthenticated(false);
                    localStorage.clear();
                    history.push('/signin');
                  }}
                >
                  {i18n.t('validateEmail.logout')}
                </TextButton>
              </Footer>
            </div>
          </>
        )}
      </RightWrapper>
    </PageTemplate>
  );
}

export default ValidateEmail;
