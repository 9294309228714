import styled from 'styled-components';
import { Title } from '@/components/atoms/Typography/styles';

export const TitleStyled = styled(Title)`
  color: white;
  font-size: 2rem;
  margin-bottom: 3.125rem;
`;

export const SkeletonCarouselMechContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 4.875rem;
  width: 100%;
`;
export const SkeletonDiscoMerchentBody = styled.div`
  align-items: center;
  display: flex;
  gap: 3.4375rem;
  justify-content: center;
`;

export const SkeletonDiscoMerchent = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.secondary};
  border-radius: 50%;
  height: 8.5rem;
  width: 8.5rem;
`;
export const SkeletonMinDiscoMerchent = styled(SkeletonDiscoMerchent)`
  height: 0.625rem;
  margin-top: auto;
  width: 0.625rem;
`;

export const SkeletonMinDiscoMerchentContainer = styled.div`
  display: flex;
  gap: 0.3125rem;
  margin-top: 1.875rem;
`;
export const SkeletonNameMerchant = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.secondary};
  height: 1.25rem;
  margin-top: 2.8125rem;
  width: 6.25rem;
`;

export const SkeletonDiscoMerchentContainer = styled.div`
  padding-top: 1.5625rem;
`;

export const SkeletonDiscoMerchentSelected = styled(SkeletonDiscoMerchent)`
  height: 13.5rem;
  width: 13.5rem;
`;
