import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    justify-content: space-between;
    margin-top: 0;
    height: 80%;
  }
`;

export const ContentWrapper = styled.div`
  align-items: center;
  animation: goRightDefault 0.6s;
  color: ${({ theme }) => theme.colors.info.primary};
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const LinksWrapper = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    align-items: center;
    animation: goUpDefault 0.4s;
    display: flex;
    flex-direction: column;
  }
`;
