import React, { useEffect, useRef, useState } from 'react';
import Joyride from 'react-joyride';

import DepositForm from '@/components/organisms/DepositForm';
import Loading from '@/components/atoms/Loading';
import MainTemplate from '@/components/templates/MainTemplate';
import PaymentCarousel from '@/components/molecules/PaymentCarousel';
import TransactionError from '@/components/molecules/TransactionError';
import TransactionPreview from '@/components/molecules/TransactionPreview';
import CustomTooltip from '@/components/v2/Molecules/CustomTooltip';
import i18n from '@/translate/i18n';
import useDeviceWidth from '@/hooks/useDeviceWidth';
import useMethods from '@/hooks/useMethods';
import useTour from '@/hooks/useTour';
import useTransactionLimits from '@/hooks/useTransactionLimits';
import {
  COLOR_COMMUM_TOOLTIP,
  DEFAULT_METHOD_SLUG,
  DELAY_TIME_5_MILISSECONDS,
  DELAY_TIME_5_SECONDS,
  FOUR_STEP_TOUR,
  SECOND_STEP_TOUR,
} from '@/helpers/constants';
import { useWalletData } from '@/context/walletContext';

import { Section, SectionDivider } from './styles';

function Deposit() {
  const { isMobileLayout } = useDeviceWidth();
  const { loadingMethods, methodsError } = useMethods();
  const [showDetails, setShowDetails] = useState(false);
  const { stateTour, updateTour, finishTour, nextStepTour } = useTour();
  const hasCalledNexStepTour = useRef(false);

  const {
    setWalletData,
    walletData: { transactionSubmitError, selectedWallet, transactionData },
  } = useWalletData();

  const { limitsError } = useTransactionLimits(
    'deposit',
    selectedWallet,
    transactionData?.method?.slug ?? DEFAULT_METHOD_SLUG,
  );

  const returnAction = () => {
    if (showDetails) {
      setShowDetails(false);
      setWalletData((prevState) => ({
        ...prevState,
        transactionSubmitError: false,
      }));
    }
  };

  const handleJoyrideCallback = (data) => {
    const isActionClose = data.action === 'close';
    const isActionNext = data.action === 'next';
    const isLifeCycleCompleted = data.lifecycle === 'complete';
    const isReadyClose = isActionClose && isLifeCycleCompleted;
    const isReadyNextStep = isActionNext && isLifeCycleCompleted;
    if (isReadyClose) {
      finishTour();
    } else if (isReadyNextStep) {
      updateTour({ stepIndex: data.index + 1 });
    }
  };

  const toGoNextStepDepositTour = () => {
    if (hasCalledNexStepTour.current) return undefined;
    hasCalledNexStepTour.current = true;
    const timeoutId = setTimeout(() => {
      nextStepTour();
    }, DELAY_TIME_5_SECONDS);
    return () => {
      hasCalledNexStepTour.current = false;
      clearTimeout(timeoutId);
    };
  };

  useEffect(
    () => () => {
      setWalletData((prevState) => ({
        ...prevState,
        transactionData: { amount: null },
        selectedBonus: null,
        transactionSubmitError: false,
      }));
    },
    [setWalletData],
  );

  useEffect(() => {
    const { run, stepIndex } = stateTour;
    const isFiveStep = stepIndex === FOUR_STEP_TOUR;

    if (run && isFiveStep) {
      toGoNextStepDepositTour();
    }
  }, [stateTour]);

  useEffect(() => {
    const { tourActive } = stateTour;
    if (!tourActive) return undefined;

    const handle = requestAnimationFrame(() => {
      const timeoutId = setTimeout(() => {
        updateTour({
          run: true,
          tourActive: true,
          stepIndex: SECOND_STEP_TOUR,
        });
      }, DELAY_TIME_5_MILISSECONDS);
      return () => clearTimeout(timeoutId);
    });
    return () => {
      cancelAnimationFrame(handle);
    };
  }, []);

  return (
    <MainTemplate
      headerProps={{
        returnAction: showDetails ? returnAction : null,
        returnTo: '/wallet',
        showAvailableBalance: true,
        title: i18n.t('deposit.title'),
      }}
    >
      <Joyride
        steps={stateTour.steps}
        tooltipComponent={CustomTooltip}
        callback={handleJoyrideCallback}
        stepIndex={stateTour.stepIndex}
        styles={{
          options: {
            arrowColor: COLOR_COMMUM_TOOLTIP,
            backgroundColor: COLOR_COMMUM_TOOLTIP,
          },
        }}
        continuous
        spotlightClicks
        run={stateTour.run}
      />
      {loadingMethods ? (
        <div className="size-full grid place-content-center">
          <Loading />
        </div>
      ) : (
        <Section isOpen>
          <PaymentCarousel
            hide={
              (showDetails && !isMobileLayout) ||
              isMobileLayout ||
              limitsError ||
              methodsError
            }
            showDetails={showDetails}
            description={i18n.t('paymentSelector.title')}
          />

          {showDetails && (!transactionSubmitError || !isMobileLayout) && (
            <TransactionPreview
              setShowDetails={setShowDetails}
              type="deposit"
              redirectTo="/deposit"
            />
          )}
          {!limitsError && !methodsError && (
            <>
              <SectionDivider />
              <DepositForm
                setShowDetails={setShowDetails}
                showDetails={showDetails}
              />
            </>
          )}
        </Section>
      )}
      {(methodsError || limitsError) && (
        <TransactionError
          content={i18n.t('error.deposit.limitsOrMethodError')}
          height="80%"
          showInstructions
        />
      )}
    </MainTemplate>
  );
}

export default Deposit;
