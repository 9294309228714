import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { ReactComponent as Progress } from '@/assets/progress-indicator.svg';
import RegisterValidationErrorMessage from '@/components/molecules/RegisterValidationErrorMessage';

import TwTitle from '@/components/atoms/TwTitle';
import TwText from '@/components/v2/atoms/TwText';
import i18n from '@/translate/i18n';
import LinkButton from '../LinkButton';
import Portal from './portal';

function CheckRegistration({ error, isExternalFlow, name = '' }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasToShowTutorial, setHasToShowTutorial] = useState(false);
  return (
    <div
      className={`flex flex-col items-center justify-evenly text-center w-full h-full ${
        isExternalFlow ? '' : 'bg-black'
      } sm:bg-none sm:w-auto`}
    >
      {!error ? (
        <div className="flex flex-col items-center justify-center gap-10 md:gap-12 h-[75%] w-full">
          <Progress className="w-56 animate-spin" />
          <div className="flex flex-col gap-5 px-8">
            <TwTitle
              classList="text-center"
              text={
                <Trans
                  i18n={i18n}
                  i18nKey="validateIdentity.validatingTitleAlternative"
                  components={[<span className="text-grass-800" />]}
                />
              }
            />
            <TwText
              classList="leading-6"
              text={i18n.t('validateIdentity.validatingMessage')}
            />
          </div>
        </div>
      ) : (
        <RegisterValidationErrorMessage />
      )}
      <LinkButton
        label="Conoce a Vpag"
        className="text-[18px] cursor-pointer"
        onClick={() => setIsModalOpen(true)}
      />
      <Portal
        hasToShowTutorial={hasToShowTutorial}
        isModalOpen={isModalOpen}
        name={name}
        setHasToShowTutorial={setHasToShowTutorial}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
}

CheckRegistration.propTypes = {
  validated: PropTypes.bool,
}.isRequired;

export default CheckRegistration;
