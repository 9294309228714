import styled, { css } from 'styled-components';

export const ContentWrapper = styled.article`
  ${({ theme }) => css`
    align-items: center;
    color: ${theme.colors.info.primary};
    display: flex;
    flex-direction: column;
    font: ${theme.font.size.xSmall} ${theme.font.family.secondary};
    max-height: 90%;
    overflow-x: hidden;
  `}
`;
