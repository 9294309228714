import React, { useEffect, useRef } from 'react';
import Joyride from 'react-joyride';
import { useHistory } from 'react-router-dom';

import Carousel from '@/components/organisms/Carousel';
import GetStart from '@/components/organisms/GetStart';
import MainTemplate from '@/components/templates/MainTemplate';
import { useUserData } from '@/context/userContext';
import CafValidation from '@/components/organisms/CafValidation';
import CustomTooltip from '@/components/v2/Molecules/CustomTooltip';
import ModalStartDepositTour from '@/components/v2/organisms/ModalStartDepositTour';

import useUserStatus from '@/hooks/useUserStatus';
import useAllMerchants from '@/hooks/useAllMerchants';
import useTour from '@/hooks/useTour';

import { tourDeposit } from '@/tours/tours';
import { carouselData } from './data';
import {
  COLOR_COMMUM_TOOLTIP,
  DELAY_TIME_5_MILISSECONDS,
  SECOND_STEP_TOUR,
} from '@/helpers/constants';

const PENDING_STATUS = 2;

function WalletHome() {
  const ref = useRef(null);
  const { getUserStatusAndRedirect } = useUserStatus();
  const { getAllMerchants } = useAllMerchants();
  const history = useHistory();
  const {
    userData: { status },
  } = useUserData();
  const { stateTour, setupTour, updateTour, finishTour } = useTour();

  const isPending = status === PENDING_STATUS;
  const pendingBackground = isPending ? 'black' : '';

  const handleJoyrideCallback = (data) => {
    const { action, index, type, step, lifecycle } = data;
    const isSecondStep = index === 1;
    const isAfterTypeStep = type === 'step:after';
    const isActionNext = action === 'next';
    const isActionClose = action === 'close';
    const isLifecycleComplete = lifecycle === 'complete';
    const isAlreadyStepGoToDeposit = isSecondStep && isAfterTypeStep;

    if (isActionNext && isAlreadyStepGoToDeposit) {
      updateTour({ run: false, stepIndex: SECOND_STEP_TOUR });
      history.push(step.data.next);
    } else if (isActionClose && isLifecycleComplete) {
      finishTour();
    }
  };

  useEffect(() => {
    getUserStatusAndRedirect(status);
  }, [getUserStatusAndRedirect, status]);

  useEffect(() => {
    const controller = new AbortController();
    const merchantList =
      localStorage.getItem('merchants') !== 'undefined'
        ? JSON.parse(localStorage.getItem('merchants'))
        : null;
    if (!merchantList) {
      getAllMerchants();
    }
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const handle = requestAnimationFrame(() => {
      const timeoutId = setTimeout(() => {
        setupTour({
          run: false,
          steps: tourDeposit,
          stepIndex: 0,
        });
      }, DELAY_TIME_5_MILISSECONDS);
      return () => clearTimeout(timeoutId);
    });
    return () => {
      cancelAnimationFrame(handle);
    };
  }, []);

  return (
    <>
      <MainTemplate
        containerProps={{
          gradient: true,
          background: pendingBackground,
        }}
        headerProps={{
          mobileFullVersion: true,
          background: pendingBackground,
        }}
        wrapperProps={{ background: pendingBackground }}
      >
        {isPending ? (
          <CafValidation />
        ) : (
          <>
            <div className="flex justify-around items-start sm:items-center size-full md:py-10">
              <GetStart />
              {stateTour.run && (
                <Joyride
                  steps={stateTour.steps}
                  tooltipComponent={CustomTooltip}
                  callback={handleJoyrideCallback}
                  ref={ref}
                  styles={{
                    options: {
                      arrowColor: COLOR_COMMUM_TOOLTIP,
                      backgroundColor: COLOR_COMMUM_TOOLTIP,
                    },
                  }}
                  continuous
                  spotlightClicks
                  stepIndex={stateTour.stepIndex}
                  run={stateTour.run}
                  disableBeacon
                />
              )}
              <div className="min-w-sm:block min-w-sm:min-h-[80%] min-w-sm:border-l-2 min-w-sm:border-solid min-w-sm:border-gray-100/20 animate-fade_in" />
              <section className="flex flex-col justify-start w-[90%] min-w-sm:w-1/2 items-center text-gray-100 animate-go_Left_Default">
                <div className="hidden min-w-sm:flex min-w-sm:w-4/5">
                  <Carousel data={carouselData} />
                </div>
              </section>
            </div>
          </>
        )}
        <ModalStartDepositTour />
      </MainTemplate>
    </>
  );
}

export default WalletHome;
